import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import { allNotifications } from "services/notifaction";
import Locale from "translations";
import NotificationContent from "./notificationContent";

export default function Notification() {
	const { notifaction } = Locale;

	const [notifyData, setNotifyData] = useState();
	const [meta, setMeta] = useState([]);
	const [page, setPage] = useState(1);

	const notifactionData = async () => {
		const res = await allNotifications(page);
		
		setNotifyData([...res?.data?.data]);
		if (res?.data?.meta) {
			setMeta(res?.data?.meta);
		}
		
	};
	useEffect(() => {
		notifactionData();
	}, [page]);

	// useEffect(() => {
	// 	async function fetchData() {
	// 		const res = await allNotifications(page);
	// 		setNotifyData(res?.data?.data);
	// 		setMeta(res?.data?.meta);
	// 	}
	// 	fetchData();
	// }, [page]);

	const AllNotification =
		notifyData?.length > 0
			? notifyData.map((data, index) => (
					<NotificationContent
						notifyData={data}
						index={index}
						notifactionData={notifactionData}
					/>
			  ))
			: [];

	const goTo = (page) => {
		setPage(page);
	};
	return (
		<div className="d-flex justify-content-center notifaction-all">
			<div className="d-flex flex-column  mx-3 notifaction-container">
				<h4 className="w-100 mx-auto mt-3 py-3 px-2 mb-0 font-weight-bold">
					{notifaction.allNotification}
				</h4>
				{AllNotification.length > 0 ? (
					AllNotification
				) : (
					<div>
						<div className="text-center my-3">
							<div className="product-build__product-no-data">
								<i className="fas fa-info-circle fa-lg"></i>{" "}
								<h4>{notifaction.noNotification}</h4>
							</div>
						</div>
					</div>
				)}

				<div className="w-100 m-auto ">
					<Pagination info={meta} goTo={goTo} items={10} />
				</div>
			</div>
		</div>
	);
}
