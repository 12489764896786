import React from 'react'
import { Link, useParams } from 'react-router-dom';
import Locale from 'translations';

export default function BreadCrump({ title, children }) {
  const { operationStatement} = Locale;
  const {  bundle } = useParams();


  return (
    <div className='umrah-operation-breadcrump px-3 gap-1'>
      <div className="text-capitalize">
        <Link to={`/${bundle}/operation-statement`}>
          <span className='link'>{operationStatement.umrahCompanyOperations}/</span>
        </Link>
        {" "} {title}
      </div>

      <div className='d-flex align-items-center flex-wrap gap-1/2'>
        {children}
      </div>
    </div>
  )
}
