import SelectField from "components/Form/SelectField/SelectField";
import React, { useRef, useState } from "react";
import validate from "helpers/validate";
import Locale from "translations";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import TextField from 'components/Form/TextField/TextField';
import PaxInput from 'modules/UmrahOperations/shared/PaxInput';
import { fetchHotels } from 'services/operationStatement';
import { ReactComponent as BedIcon } from "assets/images/product-builder/bed.svg";
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';

export default function HotelItem({
	operationHasSent,
	setErrors,
	errors,
	hotel,
	index,
	indexCity,
	cityID,
	changeHotelValue,
	changeHotelNightValue,
	deleteHotel,
	hotelLength
}) {
	const { operationStatement, productsBuilder, productElements, rooms } = Locale;
	const { mealsTypesLookup } = getOperationsLookup(Locale);
	// const fetchHotel = async (id) => {
	// 	const request = await fetchHotels(id,cityID);
	// 	if (request.hotels && request.hotels.length > 0) {
	// 		let Hotels = request.hotels.map((Hotel) => {
	// 			return {
	// 				...Hotel,
	// 				label: Hotel.name,
	// 				value: Hotel.id,
	// 			};
	// 		});
	// 		sethotels(Hotels);
	// 	}
	// };
	// useEffect(() => {
	// 	fetchHotel(966);
	// }, [cityID]);

	let goingToPrevValue = useRef(null);
	function clearSearchText(e) {
		goingToPrevValue.current = hotel.goingTo;
		const value = e.target.value;
		if (value.length > 0) {
			// setHotel({ ...hotel, goingTo: null });
		}
	}

	// AutoCompleteField
	const [listAuto, setListAuto] = useState([]);
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const Hotels = await fetchHotels("966", cityID, inputValue);

			let result = Hotels.hotels.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name,
					value: item.id,
					label: item.name,
				};
			});
			setListAuto(result);
		}
	};

	return (
		<div className="box-hotel col-md-12 my-3 p-4">
			<div className="row">
				<div className="col-12 col-md-3 col-sm-6 px-1" style={{ position: "relative" }}>
					{/* <SelectField
							label={productsBuilder.hotel+"*"}
							value={hotel?.hotel?.name}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "hotel"+indexCity+index, value: e.value },
										{ required: true }
									),
								});
								changeHotelValue(e, "hotel", indexCity, index);
							}}
							options={hotels}
							placeholder={productsBuilder.select}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{
											name: "hotel"+indexCity+index,
											value: hotel?.hotel?.id,
										},
										{ required: true }
									),
								})
							}
							color={errors["hotel"+indexCity+index]?.required ? "danger" : ""}
							errors={errors["hotel"+indexCity+index]}
						/> */}

					<AutoCompleteField
						hasLabel={true}
						// flag={removeInput ? hotel.goingTo?.country?.flag : ""}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						label={productsBuilder.hotel + "*"}
						isSearchable={true}
						placeholder={productsBuilder.hotel + "*"}
						//value={hotel.goingTo?.name}
						value={hotel?.hotel?.name || ""}
						onChange={(e) => {
							setErrors({
								...errors,
								...validate(
									{ name: "hotel" + indexCity + index, value: e.value },
									{ required: true }
								),
							});
							changeHotelValue({ name: e }, "hotel", indexCity, index);


						}}
						onSelectValue={(e) => {
							changeHotelValue(e, "hotel", indexCity, index);
							setErrors({
								...errors,
								...validate(
									{ name: "hotel" + indexCity + index, value: e },
									{ required: true }
								),
							});
						}}
						onFocus={clearSearchText}
						// onBlur={() =>
						// setHotel({ ...hotel, goingTo: goingToPrevValue.current })
						// }
						color={errors['hotel' + indexCity + index]?.required ? "danger" : ""}
						errors={errors['hotel' + indexCity + index]}
						disabled={operationHasSent}
					/>
				</div>

				<div className="col-12 col-md-3 col-sm-6 px-1">
					<DatePickerField
						style={{ background: "red" }}
						label={productElements.checkInDate + "*"}
						placeholder={productElements.dateFormatPlaceholder}
						date={hotel.check_in_date}
						onChangeDate={(e) => {
							setErrors({
								...errors,
								...validate(
									{
										name: "check_in_date" + indexCity + index,
										value: e,
									},
									{ required: true, date: true }
								),
							});
							// setCheckInDate(e);
							changeHotelValue(e, "check_in_date", indexCity, index);
						}}
						color={errors['check_in_date' + indexCity + index]?.required ? "danger" : ""}
						// isOutsideRange={(day) =>
						// 	!day.isAfter(
						// 		moment(datesRange.start).subtract(1, "d"),
						// 		"day"
						// 	) || !isInclusivelyBeforeDay(day, datesRange.end)
						// }
						errors={errors['check_in_date' + indexCity + index]}
						disabled={operationHasSent}
					/>
				</div>
				<div className="col-12 col-md-3 col-sm-6 px-1">
					<div className="form-group full-input">
						<TextField
							placeholder={productsBuilder.Nonights}
							label={productsBuilder.Nonights + "*"}
							name="no_nights"
							value={hotel.no_nights}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate({ name: "no_nights" + indexCity + index, value: e.target.value }, { required: true }),
								});
								changeHotelNightValue(e.target.value, "no_nights", indexCity, index);
							}}
							color={errors['no_nights' + indexCity + index]?.required ? "danger" : ""}
							errors={errors['no_nights' + indexCity + index]}
							disabled={operationHasSent}
						/>
					</div>
				</div>
				<div className="col-12 col-md-3 col-sm-6 px-1">
					<DatePickerField
						style={{ background: "red" }}
						label={productElements.checkOutDate + "*"}
						placeholder={productElements.dateFormatPlaceholder}
						date={hotel.check_out_date}

						onChangeDate={(e) => {
							setErrors({
								...errors,
								...validate(
									{
										name: "check_out_date" + indexCity + index,
										value: e,
									},
									{ required: true, date: true }
								),
							});
							// setCheckInDate(e);
							changeHotelValue(e, "check_out_date", indexCity, index);
						}}
						color={errors['check_out_date' + indexCity + index]?.required ? "danger" : ""}
						// isOutsideRange={(day) =>
						// 	!day.isAfter(
						// 		moment(datesRange.start).subtract(1, "d"),
						// 		"day"
						// 	) || !isInclusivelyBeforeDay(day, datesRange.end)
						// }
						errors={errors['check_out_date' + indexCity + index]}
						disabled={operationHasSent}
					/>
				</div>

				<div className="col-12 col-md-3 col-sm-6">
					<div className="form-group full-input">
						<SelectField
							label={productElements.mealsType + "*"}
							value={hotel?.meal_type?.name}
							onChange={(e) => {
								setErrors({
									...errors,
									...validate(
										{ name: "meal_type" + indexCity + index, value: e.value },
										{ required: true }
									),
								});
								changeHotelValue(e, "meal_type", indexCity, index);
							}}
							options={mealsTypesLookup}
							placeholder={productsBuilder.select}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{
											name: "meal_type" + indexCity + index,
											value: hotel?.meal_type?.id,
										},
										{ required: true }
									),
								})
							}
							color={errors['meal_type' + indexCity + index]?.required ? "danger" : ""}
							errors={errors['meal_type' + indexCity + index]}
							disabled={operationHasSent}
						/>
					</div>
				</div>
			</div>
			<div className="hide-label-pax">
				<div className="row">
					<div className="col-md-2">
						<PaxInput
							icon={<BedIcon />}
							paxTypeText={rooms.x1}
							value={hotel.single}
							handleDecrease={() => {
								changeHotelValue(
									hotel.single != undefined ? +hotel.single - 1 : 1,
									"single",
									indexCity,
									index
								);
							}}
							handleIncrease={() => {
								changeHotelValue(
									hotel.single != undefined ? +hotel.single + 1 : 1,
									"single",
									indexCity,
									index
								);
							}}
							onChange={(value) => {
								changeHotelValue(
									value,
									"single",
									indexCity,
									index
								);
							}}
						/>
					</div>

					<div className="col-md-2">
						<PaxInput
							icon={<BedIcon />}
							paxTypeText={rooms.x2}
							value={hotel.double}
							handleDecrease={() => {
								changeHotelValue(
									hotel.double != undefined ? +hotel.double - 1 : 1,
									"double",
									indexCity,
									index
								);
							}}
							handleIncrease={() => {
								changeHotelValue(
									hotel.double != undefined ? +hotel.double + 1 : 1,
									"double",
									indexCity,
									index
								);
							}}
							onChange={(value) => {
								changeHotelValue(
									value,
									"double",
									indexCity,
									index
								);
							}}
						/>
					</div>
					<div className="col-md-2">
						<PaxInput
							icon={<BedIcon />}
							paxTypeText={rooms.x3}
							value={hotel.triple}
							handleDecrease={() => {
								changeHotelValue(
									hotel.triple != undefined ? +hotel.triple - 1 : 1,
									"triple",
									indexCity,
									index
								);
							}}
							handleIncrease={() => {
								changeHotelValue(
									hotel.triple != undefined ? +hotel.triple + 1 : 1,
									"triple",
									indexCity,
									index
								);
							}}
							onChange={(value) => {
								changeHotelValue(
									value,
									"triple",
									indexCity,
									index
								);
							}}
						/>
					</div>
					<div className="col-md-2">
						<PaxInput
							paxTypeText={operationStatement.Quadruple}
							value={hotel.quadruple}
							icon={<BedIcon />}
							handleDecrease={() => {
								changeHotelValue(
									hotel.quadruple != undefined ? +hotel.quadruple - 1 : 1,
									"quadruple",
									indexCity,
									index
								);
							}}
							handleIncrease={() => {
								changeHotelValue(
									hotel.quadruple != undefined ? +hotel.quadruple + 1 : 1,
									"quadruple",
									indexCity,
									index
								);
							}}
							onChange={(value) => {
								changeHotelValue(
									value,
									"quadruple",
									indexCity,
									index
								);
							}}
						/>
					</div>
					<div className="col-md-2">
						<PaxInput
							paxTypeText={operationStatement.Quintuple}
							value={hotel.quintuple}
							icon={<BedIcon />}
							handleDecrease={() => {
								changeHotelValue(
									hotel.quintuple != undefined ? +hotel.quintuple - 1 : 1,
									"quintuple",
									indexCity,
									index
								);
							}}
							handleIncrease={() => {
								changeHotelValue(
									hotel.quintuple != undefined ? +hotel.quintuple + 1 : 1,
									"quintuple",
									indexCity,
									index
								);
							}}
							onChange={(value) => {
								changeHotelValue(
									value,
									"quintuple",
									indexCity,
									index
								);
							}}
						/>
					</div>
					<div className="col-md-2">
						<PaxInput
							icon={<BedIcon />}
							paxTypeText={operationStatement.Sextuple}
							value={hotel.sextuple}
							handleDecrease={() => {
								changeHotelValue(
									hotel.sextuple != undefined ? +hotel.sextuple - 1 : 1,
									"sextuple",
									indexCity,
									index
								);
							}}
							handleIncrease={() => {
								changeHotelValue(
									hotel.sextuple != undefined ? +hotel.sextuple + 1 : 1,
									"sextuple",
									indexCity,
									index
								);
							}}
							onChange={(value) => {
								debugger
								changeHotelValue(
									value,
									"sextuple",
									indexCity,
									index
								);
							}}
						/>
					</div>
				</div>
			</div>
			{hotelLength == index + 1 && index !== 0 ? <button onClick={() => deleteHotel(indexCity, index)} className='btn-Delete-operation'>
				<span><i class="far fa-trash-alt"></i></span>
				{operationStatement.Deletehotel}
			</button> : null}
		</div>
	);
}
