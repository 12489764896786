import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField'
import React from 'react'
import Locale from 'translations';
import useTravelersPassports from './useTravelersPassports';
import useDebounce from 'hooks/useDebounce';
import NusukLoginForm from 'modules/UmrahOperations/shared/NusukLoginForm';

export default function PassportsNusukGroups(props) {
  const { onlineVisa } = Locale;
  const {
    selectedGroup,
    passportsGroups,
    setPassportsGroups,
    handleHeaderInputsChanges,
    getNusukPassports,
    hasNusukCredentials,
    setCredReload
  } = props;


  const { fetchNusukGroups } = useTravelersPassports();

  const debouncedNusukGroupsSearch = useDebounce((text) => fetchNusukGroups(text, setPassportsGroups), 600);


  return (
    <div className="row mx-0 w-100">
      {!hasNusukCredentials &&
        <div className="col-md-4 col-12">
          <NusukLoginForm setCredReload={setCredReload} />
        </div>
      }

      {hasNusukCredentials &&
        <>
          <div className="col-md-4 col-12">
            <AutoCompleteField
              label={`${onlineVisa.GroupName}`}
              placeholder={onlineVisa.GroupName}
              name="group-name"
              id="group-name"
              isSearchable={true}
              listAuto={passportsGroups || []}
              setListAuto={setPassportsGroups}
              getListAuto={(e) => debouncedNusukGroupsSearch(e)}
              value={selectedGroup?.name || ""}
              options={passportsGroups || []}
              onChange={(e) => handleHeaderInputsChanges({ key: "selectedGroup", value: { name: e } })}
              onSelectValue={(e) => handleHeaderInputsChanges({ key: "selectedGroup", value: e })}
            />
          </div>

          <button type="button" className="btn bg-nxt mt-4 px-5 py-2 rounded-lg"
            disabled={!selectedGroup?.id}
            onClick={getNusukPassports}
          >
            {onlineVisa.search}
          </button>
        </>
      }
    </div>
  )
}
