import React, { useEffect } from 'react'
import Locale from 'translations'
import PassportsHeader from './Header';
import TravelersTable from './TravelersTable';
import { updateCreatePassports } from 'services/operationStatement';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import { SaveIcon } from 'modules/UmrahOperations/shared/Icons';
import { formatViewOperationStatement } from 'modules/UmrahOperations/helper/formatUmrahOperations';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import useTravelersPassports from './useTravelersPassports';



export default function OperationStatementPassports({ OperationStatement, setOperationStatement }) {
  const { onlineVisa, operationStatement } = Locale;
  const { reference, id } = useParams();
  const location = useLocation();
  const isPublic = location.pathname.includes("public");
  const statementId = isPublic ? id : reference;


  const {
    passportsData,
    setPassportsData,
    passportsGroups,
    setPassportsGroups,
    allTravelersList,
    insertedTravelers,
    setInsertedTravelers,
    addPassportModalOpen,

    getNusukPassports,
    toggleAddPassportsModal,
    handleHeaderInputsChanges,
    handleSelectAllPassport,
    handleSelectPassport,
    handleDeletePassport,
    handleInsertSelectedPassprots,
    getPassportsListByGroupOrTag,
    getSafaGroupsAndTags,
    checkCompanyHasNusukCred,
    hasNusukCredentials,
    fetchNusukGroups,
    credReload,
    setCredReload
  } = useTravelersPassports();


  // save inserted passports
  async function saveSelectedPassports() {
    let travelersData = {
      travellers: insertedTravelers.map(passport => ({
        first_name: passport?.first_name,
        last_name: passport?.last_name,
        passport_number: passport?.passport_number,
        passport_photo: passport?.passport_photo,
        gender: passport?.gender,
        birth_date: passport?.birth_date ? moment(passport?.birth_date).format("YYYY-MM-DD") : passport?.birth_date,
        age: passport?.birth_date ? moment(moment()).diff(passport?.birth_date, "years") : null,
        country_id: passport?.country_id,
        nationality_id: passport?.nationality_id,
        relationship: passport?.RelationWithSponser?.en || passport?.RelationWithSponser || null,
        national_id: passport?.national_id?.toString() || null
      }))
    }
    const res = await updateCreatePassports(statementId, travelersData)
    if (res?.status === 200) {
      handleResponesNotification({ alertType: "success", message: "Passports added succesfully", title: "Add Update Travelers" });
      let data = await formatViewOperationStatement(res.data.data, Locale);
      setOperationStatement(data);
    }
  }



  useEffect(() => {
    if (!passportsGroups.list && OperationStatement?.travellers?.length === 0 && !isPublic && passportsData.source === "safavisa-groups") {
      getSafaGroupsAndTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OperationStatement?.travellers]);

  useEffect(() => {
    if (OperationStatement?.travellers?.length > 0) {
      setInsertedTravelers(OperationStatement.travellers)
      setPassportsData({ ...passportsData, passportInserted: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OperationStatement.travellers]);


  useEffect(() => {
    if (passportsData.source === "nusuk-groups" && !isPublic) {
      if (!hasNusukCredentials) {
        checkCompanyHasNusukCred();
        return
      }
      fetchNusukGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passportsData.source, hasNusukCredentials, credReload]);

  
  return (
    <>
      {!passportsData.passportInserted ?
        <PassportsHeader
          passportsData={passportsData}
          passportsGroups={passportsGroups}
          setPassportsGroups={setPassportsGroups}
          handleHeaderInputsChanges={handleHeaderInputsChanges}
          getPassportsListByGroupOrTag={getPassportsListByGroupOrTag}
          getNusukPassports={getNusukPassports}
          hasNusukCredentials={hasNusukCredentials}
          setCredReload={setCredReload}
        />
        : null
      }

      {/* travelers list */}
      {(allTravelersList.length > 0 || insertedTravelers.length > 0) &&
        <>
          <div className="mt-3">
            <TravelersTable
              travelersList={passportsData.passportInserted ? insertedTravelers : allTravelersList}
              insertedTravelers={insertedTravelers}
              handleSelectAllPassport={handleSelectAllPassport}
              handleSelectPassport={handleSelectPassport}
              handleDeletePassport={handleDeletePassport}
              isPassportInserted={passportsData.passportInserted}
            />
          </div>
        </>
      }
      {/* action buttons */}
      <div className="d-flex justify-content-end mt-3">
        {passportsData.passportInserted ?
          <>
            {!isPublic &&
              <button className="btn bg-nxt px-5 mx-3 py-2" onClick={toggleAddPassportsModal}>
                {operationStatement.addNew}
              </button>
            }
            <button className="btn bg-nxt d-flex align-items-center px-5 py-2" onClick={saveSelectedPassports}>
              <SaveIcon color="#D4A655" />
              <span className="mx-1">{operationStatement.save}</span>
            </button>
          </>
          :
          null
        }

        {!passportsData.passportInserted && allTravelersList.length > 0 ?
          <button
            className="btn accept-btn px-5"
            disabled={!allTravelersList.find(passport => passport?.selected)}
            onClick={() => handleInsertSelectedPassprots()}
          >
            <span className="mx-2">{onlineVisa.INSERTSELECTED}</span>
          </button>
          :
          null
        }
      </div>



      <Modal isOpen={addPassportModalOpen} size="xl" centered className="operation-modal">
        <ModalHeader toggle={toggleAddPassportsModal}>
          {operationStatement.chooseFromGroup}
        </ModalHeader>
        <ModalBody>
          <PassportsHeader
            passportsData={passportsData}
            passportsGroups={passportsGroups}
            setPassportsGroups={setPassportsGroups}
            handleHeaderInputsChanges={handleHeaderInputsChanges}
            getPassportsListByGroupOrTag={getPassportsListByGroupOrTag}
            getNusukPassports={getNusukPassports}
            hasNusukCredentials={hasNusukCredentials}
            setCredReload={setCredReload}
          />
          {allTravelersList.length > 0 &&
            <TravelersTable
              travelersList={allTravelersList}
              insertedTravelers={insertedTravelers}
              handleSelectAllPassport={handleSelectAllPassport}
              handleSelectPassport={handleSelectPassport}
              handleDeletePassport={handleDeletePassport}
              isPassportInserted={false}
            />
          }
          <button
            className="btn bg-nxt mt-3 px-5 w-100"
            disabled={!allTravelersList.find(passport => passport?.selected)}
            onClick={() => handleInsertSelectedPassprots(toggleAddPassportsModal)}
          >
            <span className="mx-2">{onlineVisa.INSERTSELECTED}</span>
          </button>
        </ModalBody>
      </Modal>



    </>
  )
}
