import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import React from "react";
import WathcIconsSrc from "assets/images/umrah-operations/watch-icon.svg";
import {
	BusTimeIcon,
	ClockTimeIcon,
} from "modules/UmrahOperations/shared/Icons";
import Locale from 'translations';
import moment from 'moment';

export default function Attractions({ state, setsettingsState }) {
	const { operationStatement } = Locale;
	const data = state?.attractions[0];
	const today=new Date().toLocaleDateString()

	return (
		<div className="item-row attraction-row">
			<h4 className="header-title">
				<ClockTimeIcon />
				<span className="mx-3">{operationStatement.Attractions}</span>
			</h4>
			<div className='item-content-wrapper'>
				<div className="item-content">
					<div className="header-tabs ">
						<div className="tab tab-blank">
							{/* <label htmlFor=""></label> */}
						</div>
						<div className="tab">
							<label htmlFor="">{operationStatement.FromMakkahhotel}</label>
						</div>
						<div className="tab">
							<label htmlFor="">{operationStatement.FromMadinahhotel}</label>
						</div>
						<div className="tab">
							<label htmlFor="">{operationStatement.FromJeddahhotel}</label>
						</div>
					</div>

					<div>
						<div className="content-tabs">
							<div className="label-data tab">
								<h1>
									<BusTimeIcon />
									<p className="d-inline mx-2 ">{operationStatement.Attendingbus}</p>
								</h1>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										hasLabel={false}
										placeholder="00:00"
	open={false}
										name="attraction_makkah"
										id="attraction_makkah"
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										date={data?.from_makkah}
										onChangeDate={(time) => {
											setsettingsState({
												...state,
												attractions: [
													{ ...state.attractions[0], from_makkah: time },
												],
											});
										}}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if(e.keyCode==32 || e.keyCode==8){
												
												setsettingsState({
													...state,
													attractions: [
														{ ...state.attractions[0], from_makkah: moment(`${today} ${"00:00"}`).toDate() },
													],
												});
											}
									}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										hasLabel={false}
										placeholder="HH:MM"
										open={false}
										id="attraction_madinah"
										name="attraction_madinah"
										date={data?.from_madinah}
										onChangeDate={(time) => {
											setsettingsState({
												...state,
												attractions: [
													{ ...state.attractions[0], from_madinah: time },
												],
											});
										}}
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if(e.keyCode==32 || e.keyCode==8){
												
												setsettingsState({
													...state,
													attractions: [
														{ ...state.attractions[0], from_madinah: moment(`${today} ${"00:00"}`).toDate() },
													],
												});
											}
									}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										hasLabel={false}
										placeholder="HH:MM"
										open={false}
										id="attraction_jeddah"
										name="attraction_jeddah"
										date={data?.from_jeddah}
										onChangeDate={(time) => {
											setsettingsState({
												...state,
												attractions: [
													{ ...state.attractions[0], from_jeddah: time },
												],
											});
										}}
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if(e.keyCode==32 || e.keyCode==8){
												
												setsettingsState({
													...state,
													attractions: [
														{ ...state.attractions[0], from_jeddah: moment(`${today} ${"00:00"}`).toDate() },
													],
												});
											}
									}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
