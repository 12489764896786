import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import React from "react";
import WathcIconsSrc from "assets/images/umrah-operations/watch-icon.svg";
import {
	ClockTimeIcon,
	MosqueTimeIcon,
} from "modules/UmrahOperations/shared/Icons";
import Locale from 'translations';
import moment from 'moment';

export default function AttractionDuration({ state, handleInputs }) {
	const { operationStatement } = Locale;

	const data = state?.attraction_durations;
	const today=new Date().toLocaleDateString()

	return (
		<div className="item-row w-100 bus-row">
			<h4 className="header-title">
				<ClockTimeIcon />
				<span className="mx-3">
					{operationStatement.Durationofthebus}
				</span>
			</h4>
			<div className='item-content-wrapper'>
				<div className="item-content">
					<div className="header-tabs-bus header-tabs ">
						<div className="tab">
							<label htmlFor="">{operationStatement.Attraction}</label>
						</div>
						<div className="tab">
							<label htmlFor="">{operationStatement.Duration}</label>
						</div>
					</div>

					<div>
						{/*** Makkah */}
						<div className="content-tabs-bus content-tabs">
							<div className="label-data tab ">
								<h1>
									<MosqueTimeIcon />
									<p className="d-inline mx-2">{operationStatement.MakkahAttraction}</p>
								</h1>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										
										hasLabel={false}
										placeholder="00:00"
	open={false}
										name="makkah_attr"
										id="makkah_attr"
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										date={
											data?.find(
												(item) => item.city_id === 3 && item.city_name == "Makkah"
											)?.duration
										}
										onChangeDate={(time) =>
											handleInputs(
												time,
												"attraction_durations",
												"Makkah",
												3,
												"duration"
											)
										}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if(e.keyCode==32 || e.keyCode==8){
												handleInputs(
													moment(`${today} ${"00:00"}`).toDate(),
													"attraction_durations",
													"Makkah",
													3,
													"duration"
												)
											}
									}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
						</div>
						{/*** Madinah */}
						<div className="content-tabs-bus content-tabs">
							<div className="label-data tab">
								<h1>
									<MosqueTimeIcon />
									<p className="d-inline mx-2">{operationStatement.MadinahAttraction}</p>
								</h1>
							</div>

							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										
										hasLabel={false}
										placeholder="00:00"
										open={false}
										
										name="madinah_attr"
										id="madinah_attr"
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										date={
											data?.find(
												(item) =>
													item.city_id === 4 && item.city_name == "Madinah"
											)?.duration
										}
										onChangeDate={(time) =>
											handleInputs(
												time,
												"attraction_durations",
												"Madinah",
												4,
												"duration"
											)
										}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if(e.keyCode==32 || e.keyCode==8){
												handleInputs(
													moment(`${today} ${"00:00"}`).toDate(),
													"attraction_durations",
												"Madinah",
												4,
												"duration"
												)
											}
									}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
						</div>
						{/*** Jeddah */}
						<div className="content-tabs-bus content-tabs">
							<div className="label-data tab">
								<h1>
									<MosqueTimeIcon />
									<p className="d-inline mx-2">{operationStatement.JeddahAttraction}</p>
								</h1>
							</div>
							<div className="tab">
								<div className="time-feild">
									<DateTimePickerField
										
										hasLabel={false}
										placeholder="00:00"
	open={false}
										name="jeddah_attr"
										id="jeddah_attr"
										showTimeSelectOnly={true}
										icon={WathcIconsSrc}
										date={
											data?.find(
												(item) =>
													item.city_id === 134 && item.city_name == "Jeddah"
											)?.duration
										}
										onChangeDate={(time) =>
											handleInputs(
												time,
												"attraction_durations",
												"Jeddah",
												134,
												"duration"
											)
										}
										dateFormat="HH:mm"
										onKeyDown={(e) => {
											if(e.keyCode==32 || e.keyCode==8){
												handleInputs(
													moment(`${today} ${"00:00"}`).toDate(),
													"attraction_durations",
													"Jeddah",
													134,
													"duration"
												)
											}
									}}
									/>
								</div>
								<span>{operationStatement.Hour}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
