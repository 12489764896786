import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations'
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import TextField from 'components/Form/TextField/TextField';
import { TimeField } from 'modules/UmrahOperations/Settings/Timing/custumTimeField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
// Context
import { useSBSState } from 'context/global';
// Helper
import validate, { isFormValid } from 'helpers/validate';
// Assets
import { PlusIcon } from 'modules/UmrahOperations/shared/Icons';
import { ReactComponent as TrashIcon } from "assets/images/serviceBuilder/trash.svg";
// Moment
import moment from 'moment';
import useSharedData from 'modules/serviceBuilder/constant/useSharedData';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
// Services
import { fetchCities, fetchTransporters, fetchVehicleTypes } from 'services/lookups';
import { addMovementByDelegateCompany } from 'services/operationStatement';
// Custom Hooks
import useDebounce from 'hooks/useDebounce';
// React Notifications
import { store } from 'react-notifications-component';
//-----------------------------------------------------------------------

const MovementForm = () => {
  // *** Hooks
  const { operationStatement, commons, productElements, interests } = Locale
  const { allCountries } = useSBSState()
  const { vehiclesModel } = useSharedData()
  const { companyInfo } = useSBSState();
  const history = useHistory()
  const isServiceCompnay = companyInfo?.company_category === "services_company"
  const { movmentTypesLookup, visaTypesLookup } = getOperationsLookup(Locale);
  // *** State
  const [movementData, setMovementData] = useState({
    trip_type: '',
    transporter_name: '',
    reservation_number: '',
    guide_name: '',
    guide_phone_code: '',
    guide_phone_number: '',
    passenger_first_name: '',
    passenger_last_name: '',
    passenger_phone_code: '',
    passenger_phone_number: '',
    passenger_nationality: '',
    visa_type: '',
    pax: '',
    vehicles: [
      {
        vehicle_type: '',
        vehicle_model: '',
        quantity: '',
      }
    ],
    movements: [{
      movement_type: '',
      pickup_city: '',
      pickup_point: '',
      dropOff_city: '',
      dropOff_point: '',
      departure_date: '',
      departure_time: '',
      trip_number: '',
    }]
  });
  const [errors, setErrors] = useState({});
  const [citiesList, setCitiesList] = useState({});
  const [vehicleTypesList, setVehicleTypesList] = useState({});
  const [listAuto, setListAuto] = useState([]);

  // *** Function
  const handleInputChange = (rowKey = '', { key, value }, index) => {
    const movementDataClone = { ...movementData }
    if (rowKey) {
      movementDataClone[rowKey][index][key] = value
      setMovementData({ ...movementDataClone })
    } if (!rowKey) {
      movementDataClone[key] = value;
      setMovementData({ ...movementDataClone })
    }
  }

  const handleAddNewSection = (key) => {
    const movementDataClone = { ...movementData }
    if (key === 'movements') {
      const newItem = {
        movement_type: '',
        pickup_city: '',
        pickup_point: '',
        dropOff_city: '',
        dropOff_point: '',
        departure_date: '',
        departure_time: '',
        trip_number: '',
      }
      movementDataClone['movements'].push(newItem)
      setMovementData(movementDataClone)
    }
    if (key === 'vehicles') {
      const newItem = {
        vehicle_type: '',
        vehicle_model: '',
        quantity: '',
      }
      movementDataClone['vehicles'].push(newItem)
      setMovementData(movementDataClone)
    }
  }

  const handleDeleteSection = ({ key, index }) => {
    const movementDataClone = { ...movementData }
    movementDataClone?.[key]?.splice(index, 1)
    setMovementData({ ...movementDataClone })
  }

  function checkFormErrors() {
    let submitError = {};
    requierdInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: movementData[key] },
          { required: true }
        )
      }
    }
    )

    movementData?.vehicles?.forEach((vehicleItem, vehcilesIndex) => {
      requiredVehiclesInputs?.forEach((key) => {
        submitError = {
          ...submitError,
          ...validate(
            { name: `${key}-${vehcilesIndex}`, value: vehicleItem[key] },
            { required: true }
          )
        }
      })
    })

    movementData?.movements?.forEach((movementItem, movementIndex) => {
      requiredMovementInputs?.forEach((key) => {
        submitError = {
          ...submitError,
          ...validate(
            { name: `${key}-${movementIndex}`, value: movementItem[key] },
            { required: true }
          )
        }
      })
    })

    setErrors({ ...submitError });
    return submitError;
  }

  const getCities = async (id) => {
    const cities = await fetchCities(id);
    setCitiesList(cities);
  };

  const getVehicleTypes = async () => {
    const vehiclesTypes = await fetchVehicleTypes();
    setVehicleTypesList(vehiclesTypes);
  }

  const getListAuto = async (inputValue) => {
    if (inputValue.length > 2) {
      const transportersRes = await fetchTransporters(1, inputValue, "966");
      let result = [];
      transportersRes.map((item) => {
        result.push({
          name: item.name,
          id: item.id,
          value: item.id,
          label: item.name,
        });
      });
      setListAuto(result);
    }
  };

  const debouncedGetListAuto = useDebounce((searchText) => getListAuto(searchText), 600);

  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (isFormValid(formErrors) === false) {
      return
    }

    let requestBody = {
      trip_type: movementData?.trip_type?.name,
      transporter_id: movementData?.transporter_name?.id,
      reservation_number: movementData?.reservation_number,
      guide_name: movementData?.guide_name,
      guide_phone_code: movementData?.guide_phone_code?.phone_code,
      guide_phone: movementData?.guide_phone_number,
      passenger: {
        first_name: movementData?.passenger_first_name,
        last_name: movementData?.passenger_last_name,
        phone: movementData?.passenger_phone_number,
        phone_code: movementData?.passenger_phone_code?.phone_code,
        nationality_id: `${movementData?.passenger_nationality?.id}`,
        visa_type: movementData?.passenger_nationality?.name,
        pax: movementData?.pax
      },
      vehicles: movementData?.vehicles?.map(vehicleItem => (
        {
          vehicle_type_id: Number(vehicleItem?.vehicle_type?.id),
          vehicle_model: Number(vehicleItem?.vehicle_model?.id),
          count: Number(vehicleItem?.quantity)
        }
      )),
      movements: movementData?.movements?.map(movementItem => (
        {
          movement_type: movementItem?.movement_type?.name,
          movement_date: `${moment(movementItem?.departure_date).format('YYYY-MM-DD')}T${movementItem?.departure_time}`,//"2024-10-12T16:32:00",
          pickup_point_context: movementItem?.pickup_point,
          destination_context: movementItem?.dropOff_point,
          pickup_city_id: movementItem?.pickup_city?.id,
          destination_city_id: movementItem?.dropOff_city?.id,
          pax: movementData?.pax,
          trip_number: movementItem?.trip_number || null
        }
      ))
    }

    const res = await addMovementByDelegateCompany(requestBody);
    if (res?.status >= 200 && res?.status < 300) {
      store.addNotification({
        title: `${interests.messages.updateSuccessfully}`,
        message: `${interests.messages.EntriesUpdateSuccess}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      history.goBack()
    }

  }

  const tripTypeOptions = [
    { id: '1', name: operationStatement?.full_course_with_sights },
    { id: '2', name: operationStatement?.full_course_without_sights },
    { id: '3', name: operationStatement?.internal_movement },
  ]

  const requierdInputs = [
    'trip_type',
    'transporter_name',
    'passenger_first_name',
    'passenger_last_name',
    'passenger_phone_number',
    'passenger_nationality',
    'visa_type',
    'visa_type',
    'pax',
  ]

  const requiredVehiclesInputs = ['vehicle_type', 'vehicle_model', 'quantity']

  const requiredMovementInputs = [
    'movement_type',
    'pickup_city',
    'pickup_point',
    'dropOff_city',
    'dropOff_point',
    'departure_date',
    'departure_time',
    // 'trip_number',
  ]

  console.log(movementData)

  useEffect(() => {
    // to prevent route if the login account not delegation company
    if (!isServiceCompnay) {
      history.goBack()
    } else {
      // fetch cities by suadi country id
      getCities(966)
      getVehicleTypes();
    }
  }, [])

  //-------- JSX Code ---------
  return (
    <div className='container-fluid  p-0'>
      <div className='operations-center-container  '>

        <form onSubmit={submit} className="mt-4 movement-form ">

          {/*@@@@ Monement Main Details @@@@ */}
          <div className='row umrah-operations-wrapper p-4  mx-0 border-0'>

            <div className="col-12 my-4">
              <h1 className='form-title' >
                {operationStatement?.movement}
              </h1>
            </div>

            {/* Trip Type */}
            <div className="col-4">
              <SelectField
                hasLabel={true}
                label={operationStatement?.trip_type}
                // placeholder={operationStatement?.country}
                id="trip_type"
                name="trip_type"
                value={movementData?.trip_type?.name}
                options={tripTypeOptions}
                onChange={(e) => {
                  handleInputChange(null, { key: 'trip_type', value: e }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "trip_type", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.trip_type?.required ? "danger" : ""}
                errors={errors?.trip_type}
              />
            </div>

            {/* Transporter Name */}
            {/* <div className="col-4 ">
              <TextField
                type="text"
                hasLabel={true}
                label={operationStatement?.transporter_name}
                placeholder={operationStatement?.transporter_name}
                name="transporter_name"
                id="transporter_name"
                value={movementData?.transporter_name}
                onChange={(e) => {
                  handleInputChange(null, { key: 'transporter_name', value: e?.target?.value }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "transporter_name", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.transporter_name?.required ? "danger" : ""}
                errors={errors?.transporter_name}
              />
            </div> */}

            {/* Transporter Name */}
            <div className="col-4 ">
              <div className="form-group">
                <AutoCompleteField
                  hasLabel={true}
                  labelInner={false}
                  isImage={false}
                  // image={fromIcom}
                  label={operationStatement?.transporter_name}
                  isSearchable={true}
                  placeholder={operationStatement?.transporter_name}
                  listAuto={listAuto}
                  setListAuto={setListAuto}
                  getListAuto={debouncedGetListAuto}
                  value={movementData?.transporter_name?.name}
                  // flag={flights.from?.country?.flag}
                  // onFocus={(e) => clearSearchText(e, "from")}
                  // onBlur={() =>
                  //   // setFlights({
                  //   //   ...flights,
                  //   //   from: goingToPrevValue.current,
                  //   // })
                  // }
                  onChange={(e) => {
                    // setFlights({
                    //   ...flights,
                    //   from: { name: e },
                    // });
                    setMovementData({ ...movementData, transporter_name: { name: e } })
                  }}
                  onSelectValue={(selectedObj) => {
                    // setFlights({
                    //   ...flights,
                    //   from: selectedObj,
                    // });
                    // handleFlightInputsChanges({ key: 'from_port', value: selectedObj });
                    handleInputChange(null, { key: 'transporter_name', value: selectedObj }, null)
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "transporter_name", value: selectedObj },
                        { required: true }
                      ),
                    });
                  }}
                  color={errors?.transporter_name?.required ? "danger" : ""}
                  errors={errors?.transporter_name}
                />
                <i class="fas fa-exchange-alt d-none"></i>
              </div>
            </div>

            {/* Reservation Number */}
            <div className="col-4 ">
              <TextField
                type="text"
                hasLabel={true}
                label={operationStatement?.reservation_number}
                placeholder={operationStatement?.reservation_number}
                name="reservation_number"
                id="reservation_number"
                value={movementData?.reservation_number}
                onChange={(e) => {
                  handleInputChange(null, { key: 'reservation_number', value: e?.target?.value }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "reservation_number", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.reservation_number?.required ? "danger" : ""}
                errors={errors?.reservation_number}
              />
            </div>

            {/* Guide Name */}
            <div className="col-4 mt-3 ">
              <TextField
                type="text"
                hasLabel={true}
                label={operationStatement?.guide_name}
                placeholder={operationStatement?.guide_name}
                name="guide_name"
                id="guide_name"
                value={movementData?.guide_name}
                onChange={(e) => {
                  handleInputChange(null, { key: 'guide_name', value: e?.target?.value }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "guide_name", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.guide_name?.required ? "danger" : ""}
                errors={errors?.guide_name}
              />
            </div>

            {/* Guide Phone Number */}
            <div className="col-4 mt-3 phone">
              {/* phone code  */}
              <div className="phone_code phone_code-fix">
                <SelectField
                  name="guide_phone_code"
                  id="guide_phone_code"
                  placeholder={operationStatement?.phone_code}
                  // disabled={operation_details.agent_id != null || isDisabled || operationHasSent}
                  value={movementData?.guide_phone_code?.phone_code}
                  onChange={(e) => {
                    handleInputChange(null, { key: 'guide_phone_code', value: e }, null)
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "guide_phone_code", value: e },
                        { required: true }
                      ),
                    });
                  }}
                  options={allCountries}
                  color={errors?.guide_phone_code?.required ? "danger" : ""}
                  errors={errors?.guide_phone_code}
                />
              </div>
              <TextField
                // disabled={movementData.agent_id != null || isDisabled || operationHasSent}
                label={operationStatement.guide_phone_number + "*"}
                placeholder={'0000 0000 000'}
                type="number"
                name="guide_phone_number"
                id="guide_phone_number"
                className="phone_number_textfield control-field__input w-100"
                value={movementData?.guide_phone_number}
                min={9}
                // max={13}
                onWheel={(e) => e.currentTarget.blur()}
                onChange={(e) => {
                  handleInputChange(null, { key: 'guide_phone_number', value: e?.target?.value }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "guide_phone_number", value: e.target.value },
                      { required: true, min: 9, max: 13 }
                    ),
                  });
                }}
                errors={errors?.guide_phone_number}
                color={
                  errors?.guide_phone_number?.required ||
                    errors?.guide_phone_number?.min ||
                    errors?.guide_phone_number?.max
                    ? "danger"
                    : ""
                }
              />
            </div>

          </div>

          {/*@@@@ Passenger Details @@@@ */}
          <div className='row umrah-operations-wrapper p-4 mt-4 mx-0 border-0'>

            <div className="col-12 my-4">
              <h1 className='form-title'>
                {operationStatement?.passenger_details}
              </h1>
            </div>

            {/* Passenger First name Name */}
            <div className="col-4 ">
              <TextField
                type="text"
                hasLabel={true}
                label={operationStatement?.passenger_first_name}
                placeholder={operationStatement?.enter_first_name}
                name="passenger_first_name"
                id="passenger_first_name"
                value={movementData?.passenger_name}
                onChange={(e) => {
                  handleInputChange(null, { key: 'passenger_first_name', value: e?.target?.value }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "passenger_first_name", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.passenger_first_name?.required ? "danger" : ""}
                errors={errors?.passenger_first_name}
              />
            </div>

            {/* Passenger Last name Name */}
            <div className="col-4 ">
              <TextField
                type="text"
                hasLabel={true}
                label={operationStatement?.passenger_last_name}
                placeholder={operationStatement?.enter_last_name}
                name="passenger_last_name"
                id="passenger_last_name"
                value={movementData?.passenger_last_name}
                onChange={(e) => {
                  handleInputChange(null, { key: 'passenger_last_name', value: e?.target?.value }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "passenger_last_name", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.passenger_last_name?.required ? "danger" : ""}
                errors={errors?.passenger_last_name}
              />
            </div>

            {/* Passenger Phone Number */}
            <div className="col-4  phone">
              {/* phone code  */}
              <div className="phone_code phone_code-fix">
                <SelectField
                  name="passenger_phone_code"
                  id="passenger_phone_code"
                  placeholder={operationStatement?.passenger_phone_code}
                  value={movementData?.passenger_phone_code?.phone_code || null}
                  onChange={(e) => {
                    handleInputChange(null, { key: 'passenger_phone_code', value: e }, null)
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: "passenger_phone_code", value: e },
                        { required: true }
                      ),
                    });
                  }}
                  options={allCountries}
                  color={errors?.passenger_phone_code?.required ? "danger" : ""}
                  errors={errors?.passenger_phone_code}
                />
              </div>
              <TextField
                label={operationStatement.passenger_phone_number + "*"}
                placeholder={'0000 0000 000'}
                type="number"
                name="passenger_phone_number"
                id="passenger_phone_number"
                className="phone_number_textfield control-field__input w-100"
                value={movementData?.passenger_phone_number}
                min={9}
                // max={13}
                onWheel={(e) => e.currentTarget.blur()}
                onChange={(e) => {
                  handleInputChange(null, { key: 'passenger_phone_number', value: e?.target?.value }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "passenger_phone_number", value: e.target.value },
                      { required: true, min: 9, max: 13 }
                    ),
                  });
                }}
                errors={errors?.passenger_phone_number}
                color={
                  errors?.passenger_phone_number?.required ||
                    errors?.passenger_phone_number?.min ||
                    errors?.passenger_phone_number?.max
                    ? "danger"
                    : ""
                }
              />
            </div>

            {/* Passenger Nationality */}
            <div className="col-4 mt-3">
              <SelectField
                hasLabel={true}
                label={operationStatement?.passenger_nationality}
                placeholder={operationStatement?.enter_passenger_nationality}
                id="trip_type"
                name="trip_type"
                value={movementData?.passenger_nationality?.name}
                options={allCountries}
                onChange={(e) => {
                  handleInputChange(null, { key: 'passenger_nationality', value: e }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "passenger_nationality", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.passenger_nationality?.required ? "danger" : ""}
                errors={errors?.passenger_nationality}
              />
            </div>

            {/* Passenger Visa Type */}
            <div className="col-4 mt-3">
              <SelectField
                hasLabel={true}
                label={operationStatement?.visa_type}
                id="visa_type"
                name="visa_type"
                value={movementData?.visa_type?.name}
                options={visaTypesLookup}
                onChange={(e) => {
                  handleInputChange(null, { key: 'visa_type', value: e }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "visa_type", value: e },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.visa_type?.required ? "danger" : ""}
                errors={errors?.visa_type}
              />
            </div>

            {/* Pax  */}
            <div className="col-4 mt-3 ">
              <TextField
                type="number"
                hasLabel={true}
                label={operationStatement?.pax}
                placeholder={operationStatement?.enter_pax}
                name="pax"
                id="pax"
                value={movementData?.pax}
                onChange={(e) => {
                  handleInputChange(null, { key: 'pax', value: e?.target?.value }, null)
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "pax", value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.pax?.required ? "danger" : ""}
                errors={errors?.pax}
              />
            </div>

          </div>


          {/*@@@@ Vehicles Details @@@@ */}
          <div className=' align-items-center umrah-operations-wrapper p-4 mt-4 mx-0 border-0'>

            <div className="w-100 my-4 d-flex justify-content-between align-items-center">
              <h1 className='form-title'>
                {operationStatement?.Vehicles}
              </h1>
              <button
                type='button'
                className="btn filled-btn mx-2 py-2"
                onClick={() => handleAddNewSection('vehicles')}
              >
                <PlusIcon color="#fff" />
                <span className="mx-1">{operationStatement.add}</span>
              </button>
            </div>

            {movementData?.vehicles?.map((vehicleItem, index) =>
              <div className={`row d-flex justify-content-between align-items-center ${index > 0 ? 'mt-4' : ''}`} >
                {/* Vehicle Type */}
                <div className="col-4">
                  <SelectField
                    hasLabel={true}
                    label={operationStatement?.vehicle_type}
                    id="vehicle_type"
                    name={`vehicle_type-${index}`}
                    value={vehicleItem?.vehicle_type?.name}
                    options={vehicleTypesList}
                    onChange={(e) => {
                      handleInputChange('vehicles', { key: 'vehicle_type', value: e }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: `vehicle_type-${index}`, value: e },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.[`vehicle_type-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`vehicle_type-${index}`]}
                  />
                </div>

                {/* Vehicle Model */}
                <div className="col-4 ">
                  <SelectField
                    hasLabel={true}
                    label={operationStatement?.vehicle_model}
                    id="vehicle_model"
                    name={`vehicle_model-${index}`}
                    value={vehicleItem?.vehicle_model?.name}
                    options={vehiclesModel}
                    onChange={(e) => {
                      handleInputChange('vehicles', { key: 'vehicle_model', value: e }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: `vehicle_model-${index}`, value: e },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.[`vehicle_model-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`vehicle_model-${index}`]}
                  />
                </div>

                {/* Quantity  */}
                <div className={` ${index === 0 ? 'col-4' : 'col-3'}`} >
                  <TextField
                    type="number"
                    hasLabel={true}
                    label={operationStatement?.quantity}
                    placeholder={operationStatement?.enter_qty}
                    name={`quantity-${index}`}
                    id="quantity"
                    value={vehicleItem?.quantity}
                    onChange={(e) => {
                      handleInputChange('vehicles', { key: 'quantity', value: e?.target?.value }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: "quantity", value: e?.target?.value },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.[`quantity-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`quantity-${index}`]}
                  />
                </div>

                {index > 0 &&
                  <div className="col-1 mt-2 ">
                    <TrashIcon
                      type='button'
                      className='mx-2 pointer-event'
                      onClick={() => handleDeleteSection({ key: 'vehicles', index })}
                    />
                  </div>
                }
              </div>
            )}
          </div>


          {/*@@@@ Movements Details @@@@ */}
          <div className='row align-items-center umrah-operations-wrapper p-4 mt-4 mx-0 border-0'>
            <div className="col-12 my-4 d-flex justify-content-between align-items-center">
              <h1 className='form-title'>
                {operationStatement?.movements}
              </h1>
              <button
                type='button'
                className="btn filled-btn mx-2 py-2"
                onClick={() => handleAddNewSection('movements')}
              >
                <PlusIcon color="#fff" />
                <span className="mx-1">{operationStatement.add}</span>
              </button>
            </div>
            {movementData?.movements?.map((movementItem, index) =>
              <div className={`row movements-wrapper ${index > 0 ? 'mt-4' : ''} `}>

                {/* Movement Type */}
                <div className="col-4">
                  <SelectField
                    hasLabel={true}
                    label={operationStatement?.movement_type}
                    id="movement_type"
                    name={`movement_type-${index}`}
                    value={movementItem?.movement_type?.name}
                    options={movmentTypesLookup}
                    onChange={(e) => {
                      handleInputChange('movements', { key: 'movement_type', value: e }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: `movement_type-${index}`, value: e },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.[`movement_type-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`movement_type-${index}`]}
                  />
                </div>

                {/* Pick Up City */}
                <div className="col-4 ">
                  <SelectField
                    hasLabel={true}
                    label={operationStatement?.pickup_city}
                    id="pickup_city"
                    name={`pickup_city-${index}`}
                    value={movementItem?.pickup_city?.name}
                    options={citiesList}
                    onChange={(e) => {
                      handleInputChange('movements', { key: 'pickup_city', value: e }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: `pickup_city-${index}`, value: e },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.[`pickup_city-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`pickup_city-${index}`]}
                  />
                </div>

                {/* Pick Up Point  */}
                <div className="col-4  ">
                  <TextField
                    type="text"
                    hasLabel={true}
                    label={operationStatement?.pickup_point}
                    placeholder={operationStatement?.pickup_point}
                    name={`pickup_point-${index}`}
                    id="pickup_point"
                    value={movementItem?.pickup_point}
                    onChange={(e) => {
                      handleInputChange('movements', { key: 'pickup_point', value: e?.target?.value }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: `pickup_point-${index}`, value: e?.target?.value },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.[`pickup_point-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`pickup_point-${index}`]}
                  />
                </div>

                {/* Drop Off City  */}
                <div className="col-4 mt-3">
                  <SelectField
                    hasLabel={true}
                    label={operationStatement?.dropOff_city}
                    id="dropOff_city"
                    name={`dropOff_city-${index}`}
                    value={movementItem?.dropOff_city?.name}
                    options={citiesList}
                    onChange={(e) => {
                      handleInputChange('movements', { key: 'dropOff_city', value: e }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          {
                            name: `dropOff_city-${index}`, value: e
                          },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.[`dropOff_city-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`dropOff_city-${index}`]}
                  />
                </div>

                {/* Drop Off Point  */}
                <div className="col-4 mt-3 ">
                  <TextField
                    type="text"
                    hasLabel={true}
                    label={operationStatement?.dropOff_point}
                    placeholder={operationStatement?.dropOff_point}
                    name={`dropOff_point-${index}`}
                    id="dropOff_point"
                    value={movementItem?.dropOff_point}
                    onChange={(e) => {
                      handleInputChange('movements', { key: 'dropOff_point', value: e?.target?.value }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: `dropOff_point-${index}`, value: e?.target?.value },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.[`dropOff_point-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[`dropOff_point-${index}`]}
                  />
                </div>

                {/* Pax  */}
                <div className="col-4 mt-3 ">
                  <TextField
                    disabled={movementData?.pax}
                    type="number"
                    hasLabel={true}
                    label={operationStatement?.pax}
                    placeholder={operationStatement?.enter_pax}
                    name="pax"
                    id="pax"
                    value={movementData?.pax}
                    onChange={(e) => {
                      // handlePackageInputsChanges({ key: 'name_ar', value: e?.target?.value })
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: "pax", value: e?.target?.value },
                          { required: true }
                        ),
                      });
                    }}
                    color={errors?.pax?.required ? "danger" : ""}
                    errors={errors?.pax}
                  />
                </div>

                {/* Departure Date  */}
                <div className="col-4 mt-3 ">
                  <DatePickerField
                    label={operationStatement.departure_date}
                    placeholder="DD/MM/YYYY"
                    id="departure_date"
                    name={`departure_date-${index}`}
                    date={
                      movementItem?.departure_date
                        ? moment(movementItem?.departure_date)
                        : ""
                    }
                    value={moment(movementItem?.departure_date)}
                    onChangeDate={(date) => {
                      handleInputChange('movements', { key: 'departure_date', value: date }, index)
                      setErrors({
                        ...errors,
                        ...validate(
                          { name: `departure_date-${index}`, value: date },
                          { required: true }
                        ),
                      })

                    }
                    }
                    color={errors?.[`departure_date-${index}`]?.required ? "danger" : ""}
                    errors={errors?.[[`departure_date-${index}`]]}
                    isOutsideRange={() => false}
                  />
                </div>

                {/* Departure Time  */}
                <div className="col-4 mt-3 ">
                  <TimeField
                    changeValue={(hours, minutes) => {
                      // setOperationStatement({
                      //   ...OperationStatement,
                      //   trip: {
                      //     ...arrival_departure,
                      //     // arrival_date: `${moment(arrival_departure?.arrival_date).format("YYYY-MM-DD")}T${hours}:${minutes}`,
                      //     arrival_time: `${hours}:${minutes}`,
                      //   },
                      // });
                      handleInputChange('movements', { key: 'departure_time', value: `${hours}:${minutes}` }, index)
                    }}
                    label={operationStatement.departure_time}
                    value={{
                      hours: movementItem?.departure_time?.split(":")[0] || (movementItem?.departure_time && moment(movementItem?.departure_time)?.format("HH")),
                      minutes: movementItem?.departure_time?.split(":")[1] || (movementItem?.departure_time && moment(movementItem?.departure_time)?.format("mm")),
                    }}
                    errorMsg={
                      errors?.[`departure_time-${index}`]?.required
                        ? productElements.tripDuration + " " + commons.isRequired
                        : false
                    }
                    color={errors?.[`departure_time-${index}`]?.required ? "danger" : ""}
                  />
                </div>

                {/* Trip Number   */}
                {
                  (movementItem?.movement_type?.id === 'arrival' ||
                    movementItem?.movement_type?.id === 'departure') &&
                  <div className="col-4 mt-3 ">
                    <TextField
                      type="text"
                      hasLabel={true}
                      label={operationStatement?.trip_number}
                      placeholder={operationStatement?.enter_trip_no}
                      name={`trip_number-${index}`}
                      id="trip_number"
                      value={movementItem?.trip_number}
                      onChange={(e) => {
                        handleInputChange('movements', { key: 'trip_number', value: e?.target?.value }, index)
                        setErrors({
                          ...errors,
                          ...validate(
                            { name: `trip_number-${index}`, value: e?.target?.value },
                            { required: true }
                          ),
                        });
                      }}
                      color={errors?.[`trip_number-${index}`]?.required ? "danger" : ""}
                      errors={errors?.[`trip_number-${index}`]}
                    />
                  </div>
                }


                {/* Delete Btn Movement Item Row */}
                {index > 0 &&
                  <div className='col-12 mt-4 d-flex justify-content-end'>
                    <button
                      type='button'
                      className="btn delete-btn  mx-2 py-2"
                      onClick={() => handleDeleteSection({ key: 'movements', index })}
                    >
                      <TrashIcon />
                      <span className='mx-1' >{operationStatement.delete}</span>
                    </button>
                  </div>
                }
              </div>
            )}
          </div>

          {/* Save Button */}
          <div className="col-12 mt-4 d-flex justify-content-end gap-10 mt-3">
            <button type="submit" className="submit-btn ">
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {operationStatement?.save}
              </span>
            </button>
          </div>

        </form>

      </div >
    </div >
  )
}

export default MovementForm
