import React, { useEffect, useState } from 'react'
// Transalation
import Locale from 'translations';
// React-Strap
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
// Asstets
import { ReactComponent as SaveIcon } from "assets/images/umrah-operations/save-icon.svg";
// Helper
import validate, { isFormValid } from "helpers/validate";
import { fetchTransporters } from 'services/lookups';
//-------------------------------------------------------------------------------------------------------
const EditTrasportationModal = ({ toggle, transportationData, setTransportaionModal, sendTransportationData }) => {

  const { operationStatement, marketPlace } = Locale;
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false)
  const [transporters, setTransporters] = useState([])

  function handleInputChanges(e) {
    setTransportaionModal({
      ...transportationData,
      transportation: e
    });
    // console.log(e)

    setErrors({
      ...errors,
      ...validate(
        { name: 'transportation', value: e },
        { required: true }
      ),
    })
  }
  // const transportaionOptions = [transportationData?.alternativeTransportation]
  const requiredInput = ['transportation']

  const getTransporters = async () => {
    const transportersList = await fetchTransporters(1);
    setTransporters(transportersList)
    setTransportaionModal({
      ...transportationData,
      transportation: transportationData?.transporter
    })
  }

  function checkFormErrors() {
    let submitErrors = {}
    requiredInput.forEach((key) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: transportationData?.[key] },
          { required: true }
        ),
      }
    })
    setErrors({ ...submitErrors });
  }

  async function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      sendTransportationData(
        transportationData?.movementId,
        { transporter_id: transportationData?.transportation?.id  }
      )
    }
  }, [isErrorLoaded]);

  useEffect(() => {
    getTransporters()
  }, []);


  console.log(transportationData)

  // ---------- JSX Code -----------
  return (
    <Modal isOpen={transportationData?.isOpen} centered className='operation-modal'>
      <ModalHeader toggle={toggle} className='align-items-center'>
        {operationStatement.edit_transportation}
      </ModalHeader>
      <ModalBody>

        <div className="mt-3">
          <div className="row mx-0">
            {/* hall */}
            <div className="col-12 company_phone_code">
              <SelectField
                label={operationStatement.transportation}
                id="transportation"
                name="transportation"
                value={transportationData?.transportation?.name}
                onChange={(e) => handleInputChanges(e)}
                options={transporters}
                color={errors?.transportation?.required ? "danger" : ""}
                errors={errors?.transportation}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <button className='btn save-btn' onClick={submit}>
            <SaveIcon />
            <span className='mx-1'>{marketPlace.SaveChanges}</span>
          </button>
        </div>

      </ModalBody>
    </Modal>
  )
}

export default EditTrasportationModal
