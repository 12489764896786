import React, { useEffect, useState } from 'react'
// Transalation
import Locale from 'translations';
// React-Strap
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
// Asstets
import { ReactComponent as SaveIcon } from "assets/images/umrah-operations/save-icon.svg";
// Helper
import validate, { isFormValid } from "helpers/validate";
// ------------------------------------------------------------------------------------------------------------------

const EditHallModal = ({ toggle, hallData, setHallModal, movmentsList, sendHallData }) => {

  const { operationStatement, marketPlace } = Locale;
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  const destenationsHallsList = movmentsList?.find(item => item?.id === hallData?.movementId)?.destinations?.[hallData?.index]?.available_halls
  const pickPointHallsList = movmentsList?.find(item => item?.id === hallData?.movementId)?.pickupPoints?.[hallData?.index]?.available_halls

  const item_type = hallData?.type === 'destinations' ?
    movmentsList?.find(item => item?.id === hallData?.movementId)?.destinations?.[hallData?.index]?.item_type
    :
    movmentsList?.find(item => item?.id === hallData?.movementId)?.pickupPoints?.[hallData?.index]?.item_type

  const item_id = hallData?.type === 'destinations' ?
    movmentsList?.find(item => item?.id === hallData?.movementId)?.destinations?.[hallData?.index]?.item_id
    :
    movmentsList?.find(item => item?.id === hallData?.movementId)?.pickupPoints?.[hallData?.index]?.item_id

  function handleInputChanges(e) {
    setHallModal({
      ...hallData,
      hall: e
    });
    // console.log(e)

    setErrors({
      ...errors,
      ...validate(
        { name: 'hall', value:e },
        { required: true }
      ),
    })
  }
  
  const requiredInput = ['hall']

  function checkFormErrors() {
    let submitErrors = {}
    requiredInput.forEach((key) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: hallData?.[key] },
          { required: true }
        ),
      }
    })
    setErrors({ ...submitErrors });
  }

  async function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      sendHallData(
        hallData?.movementId,
        {
          item_type: item_type,
          item_id: item_id,
          hall_id: hallData?.hall?.id,
        }
      )
    }
  }, [isErrorLoaded]);

  //   console.log(item_type)
  //   console.log(item_id)
  // console.log(hallData?.index)

  // console.log(movmentsList?.find(item => item?.id === hallData?.movementId))
  console.log(hallData)
  // console.log(movmentsList)

  // ---------- JSX  Code --------- 
  return (
    <Modal isOpen={hallData?.isOpen} centered className='operation-modal'>
      <ModalHeader toggle={toggle} className='align-items-center'>
        {operationStatement.edit_hall}
      </ModalHeader>
      <ModalBody>

        <div className="mt-3">
          <div className="row mx-0">
            {/* hall */}
            <div className="col-12 company_phone_code">
              <SelectField
                label={operationStatement.available_halls}
                id="supervisor_phone_code"
                name="hall"
                value={hallData?.hall?.name}
                onChange={(e) => handleInputChanges(e)}
                options={hallData?.type === 'destinations' ? destenationsHallsList : pickPointHallsList}
                color={errors?.hall?.required ? "danger" : ""}
                errors={errors?.hall}
              />
            </div>
          </div>
        </div>

        <div className="col-12">
          <button className='btn save-btn' onClick={submit}>
            <SaveIcon />
            <span className='mx-1'>{marketPlace.SaveChanges}</span>
          </button>
        </div>

      </ModalBody>
    </Modal>
  )
}

export default EditHallModal