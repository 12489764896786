import TextField from 'components/Form/TextField/TextField';
import React, { useCallback, useEffect, useState } from 'react';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import Locale from 'translations';
import { useSBSState } from 'context/global';
import { changeGroupStatus, deleteGroup, fetchOfficersGroupsList } from 'services/operationStatement';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import Pagination from 'components/Pagination';
import { debounce } from "lodash";
import AddEditMovementGroupModal from './GroupModal';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AlertCircleIcon } from 'modules/UmrahOperations/shared/Icons';
import ShowForPermission from 'helpers/showForPermission';






const initialModalData = {
  groupName: "",
  available_time: null,
  city: null,
  airport: null,
  hall: null
}

export default function OfficialMovementGroup({ groupsList, groupsMeta, setMovementGroups }) {
  // hooks
  const { marketPlace, CRM, movementManagement } = Locale;
  const { locale, permissions, role } = useSBSState();
  // states
  const [searchTerm, setSearchTerm] = useState(null);
  const [groupModal, setGroupModal] = useState({
    isOpen: false,
    id: null,
    data: initialModalData,
    type: null
  });
  const [deleteGroupModal, setDeleteGroupModal] = useState({
    isOpen: false,
    id: null,
  })


  // ** functions

  // toggle group modal
  function toggleGroupModal({ data, type, id }) {
    setGroupModal(prev => {
      return {
        isOpen: !prev.isOpen,
        data,
        type,
        id
      }
    });
  }

  function toggleDeleteGroupModal({ id }) {
    setDeleteGroupModal(prev => {
      return {
        isOpen: !prev.isOpen,
        id
      }
    });
  }


  // handle delete group
  async function handleDeleteGroup(groupId) {
    if (!groupId) return;
    const res = await deleteGroup(groupId);
    let message;
    if (res?.status === 200) {
      message = "Group Deleted Succesfully";
      handleResponesNotification({ alertType: "success", message, title: "" });

      getGoupsList({});
    } else {
      message = "Faild to Delete Group";
      handleResponesNotification({ alertType: "danger", message, title: "" });

    }
    toggleDeleteGroupModal({})
  }

  // change group status (active, inactive)
  async function handleChangeGroupStatus({ groupId, isActive }) {
    if (!groupId) return;
    let data = { is_active: isActive }
    const res = await changeGroupStatus(groupId, data);
    let message;
    if (res?.status === 200) {
      message = "Group status changed Succesfully";
      handleResponesNotification({ alertType: "success", message, title: "" });

      getGoupsList({ page: null, search: searchTerm });
    }
  }

  // handle go to page 
  function goTo(page) {
    getGoupsList({ page })
  };


  // get groups list
  const getGoupsList = useCallback(async (params) => {
    const res = await fetchOfficersGroupsList(params);
    if (res?.status === 200) {
      setMovementGroups({ list: res?.data?.data, meta: res?.data?.meta });
    }
  }, [setMovementGroups]);

  // ** component effects

  // get initial groups list
  useEffect(() => {
    if (!groupsList) {
      getGoupsList({})
    }
  }, [getGoupsList, groupsList]);

  // get groups when search term changes
  useEffect(() => {
    const debouncedSearch = debounce(async () => {
      await getGoupsList({ page: null, search: searchTerm });
    }, 500);

    if (searchTerm !== null) {
      debouncedSearch();
    }
    // Cleanup function to cancel pending debounced calls on unmount
    return () => debouncedSearch.cancel();
  }, [getGoupsList, searchTerm]);


  return (
    <div className='movement-tab'>

      <div className="d-flex align-items-center flex-wrap gap-1 justify-content-between mb-3 min-w-120">
        <div className='flex-sm-grow-1 flex-xl-grow-0 w-50 flex-shrink-0 p-0'>
          <TextField
            type="text"
            id="group-search"
            name="group-search"
            label={marketPlace.messages.search}
            placeholder={marketPlace.messages.search}
            isImage={true}
            image={SeachIcon}
            prependImage={true}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </div>
        <ShowForPermission permission="Manage-Official-Management">
          <button 
            onClick={() => toggleGroupModal({ data: initialModalData, type: "Add" })} 
            className="add-btn align-self-end btn flex-grow-1 flex-md-grow-0 h-44 justify-content-center mb-1/4 min-w-fit-content"
          >
            <i className="fas fa-plus"></i>
            {Locale.movementManagement.addDelegateGroup}
          </button>
        </ShowForPermission>
      </div>

      <ShowForPermission permission="View-Official-Management">
        <div className="table-container">
          <table className="table table-update w-100 mb-0">
            <thead>
              <tr>
                <th scope="col">{Locale.movementManagement.groupName}</th>
                <th scope="col">{Locale.movementManagement.delegationCount}</th>
                <th scope="col">{Locale.movementManagement.city}</th>
                <th scope="col">{Locale.movementManagement.availableTime}</th>
                <th scope="col">{Locale.movementManagement.status}</th>
                <ShowForPermission permission="Manage-Official-Management">
                  <th scope="col">{Locale.movementManagement.action}</th>
                </ShowForPermission>
              </tr>
            </thead>
            <tbody>
              {groupsList?.length > 0
                ? groupsList?.map(group => {
                  return (
                    <tr key={group.id}>
                      <td><span className="text-black-900">{group?.name || "-"}</span></td>
                      <td>{group?.officers_count || "0"}</td>
                      <td>{group?.city?.names[locale] || group?.city?.name || "-"}</td>
                      <td>
                        {group?.available_time ?
                          `${group?.available_time} ${(group?.available_time === "24" || group?.available_time === "12") && locale === "ar"
                            ? Locale.movementManagement.hour : Locale.movementManagement.hours}`
                          :
                          "-"
                        }
                      </td>
                      <td>
                        <div className="switch-container">
                          <input
                            type="checkbox"
                            className="switch"
                            id={`switch-${group?.id}`}
                            name={`switch-${group?.id}`}
                            checked={group?.is_active}
                            value={group.is_active}
                            onChange={(e) => { handleChangeGroupStatus({ groupId: group?.id, isActive: +e.target.value ? 0 : 1 }) }}
                            disabled={!permissions.includes("Manage-Official-Management") && role !== "super-admin"}
                          />
                          <label htmlFor={`switch-${group?.id}`} className="switch-label mb-0"></label>
                        </div>
                      </td>
                      <ShowForPermission permission="Manage-Official-Management">
                        <td className="table-actions">
                          {/* edit group button */}
                          <button className="btn shadow-none p-0"
                            onClick={() => toggleGroupModal({
                              data: {
                                groupName: group?.name,
                                available_time: { id: group?.available_time, name: group?.available_time },
                                city: group?.city,
                                airport: group?.port,
                                hall: group?.hall
                              },
                              id: group?.id,
                              type: "Edit"
                            })
                            }
                          >
                            <EditIcon />
                          </button>

                          {/* delete group button */}
                          {!group?.is_active ? <button className="btn shadow-none p-0 delete-btn"
                            onClick={() => toggleDeleteGroupModal({ id: group?.id })}
                          >
                            <DeleteIcon />
                          </button>
                            : null
                          }
                        </td>
                      </ShowForPermission>
                    </tr>
                  )
                })
                :
                <tr><td colSpan={6} className="text-center">{CRM.NoData}</td></tr>
              }

            </tbody>
          </table>
        </div>
      </ShowForPermission>

      {/* list Pagination */}
      <div className="mt-3">
        <Pagination
          info={groupsMeta}
          goTo={goTo}
        />
      </div>


      <AddEditMovementGroupModal
        groupModal={groupModal}
        setGroupModal={setGroupModal}
        toggleGroupModal={toggleGroupModal}
        getGoupsList={getGoupsList}
      />

      {deleteGroupModal.isOpen &&
        <Modal className="confirm-theme-modal" isOpen={deleteGroupModal.isOpen} centered>
          <ModalHeader toggle={toggleDeleteGroupModal}></ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column gap-10 align-items-start">
              <span className="icon mb-2">
                <AlertCircleIcon color="#D92D20" />
              </span>
              <h6 className="header">{movementManagement.unpublish}</h6>
              <p className="info-text">{movementManagement.unpublishQes} </p>
            </div>
            <div className="d-flex gap-10 mt-4">

              <button className="btn reject-btn" onClick={toggleDeleteGroupModal}>
                {movementManagement.no}
              </button>

              <button className="btn confirm-btn"
                onClick={() => handleDeleteGroup(deleteGroupModal?.id)}
              >
                {movementManagement.yes}
              </button>
            </div>
          </ModalBody>
        </Modal>
      }

    </div>
  );
}



