import TextField from 'components/Form/TextField/TextField'
import React, { useState } from 'react'
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import Locale from 'translations';
import SelectField from 'components/Form/SelectField/SelectField';
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/umrah-operations/search-icon.svg";
import { useSBSState } from 'context/global';
import { fetchMultiTransporters } from 'services/operationStatement';
import moment from 'moment';
import DateRangeField from 'components/Form/DateRangeField/DateRangeField';
import { MultiSelect } from 'react-multi-select-component';
import { ReactComponent as FilterIcon } from "assets/images/umrah-operations/filter-icon.svg";

export default function OperationStatementFilter({ filters, setFilters, allItem, resetFilters, fetchStatementList, lookups }) {
  const { operationStatement, marketPlace, inventory, payment, productsBuilder } = Locale;
  const { locale } = useSBSState();


  const [options] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  async function filterTransporterOptions(options, filter) {
    if (!filter) {
      return options;
    }
    let optionsArr = []
    if (filter?.length > 2) {
      const params = {
        transportation_type_id: filters?.trip_types?.find(item => item?.value === "all") ? null : filters?.trip_types?.map(type => type?.id),
        name: filter
      }
      const res = await fetchMultiTransporters(params);
      let result = []
      res?.forEach(item => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        })
      });
      optionsArr = result;
      return optionsArr;
    } else {
      return optionsArr
    }
  };

  function valueRenderer(selected) {
    if (!selected.length) {
      return operationStatement.NoItemSelected;
    }
    return "Item Selected  " + selected.length;
  };



  return (
    <>
      {/* Floating Filter button */}
      <div className="floating-filter-btn d-block d-md-none">
        <button className='btn filter-collapse-btn opened px-0'
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        >
          <FilterIcon />
        </button>
      </div>

      <div className={`operation-statement-filter p-3 ${isFilterOpen ? "open-aside" : "close-aside"}`}>
        <div className='d-flex w-100 d-md-none'>
          <button className="close-filter-btn" onClick={() => setIsFilterOpen(!isFilterOpen)}>
            X
          </button>
        </div>
        <div className="row w-100 mx-0">
          {/* search */}
          <div className="col-12 col-md-4 col-lg-2">
            <TextField
              type="text"
              id="statement-operation-no"
              name="statement-operation-no"
              label={marketPlace.messages.search}
              placeholder={operationStatement.searchEX}
              isImage={true}
              image={SeachIcon}
              prependImage={true}
              value={filters?.search}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  search: e.target.value,
                });
              }}
            />
          </div>
          {/* agent name */}
          <div className="col-12 col-md-4 col-lg-2 multi-select">

            <SelectField
              multi
              id="statement-agent"
              name="statement-agent"
              label={operationStatement.agentName}
              options={filters?.agent_ids?.find(item => item?.value === "all") ? [] : lookups.agents}
              placeholder={filters.agent_ids}
              onChange={(values, { action }) => {
                if (action === "remove-value" && !values) {
                  setFilters({ ...filters, agent_ids: [] });
                  return;
                }
                if (values?.find(item => item?.value === "all")) {
                  setFilters({ ...filters, agent_ids: [allItem] })
                  return;
                }
                setFilters({ ...filters, agent_ids: values })
              }}
            />
          </div>
          {/* country */}
          <div className="col-12 col-md-4 col-lg-2 multi-select">
            <SelectField
              multi
              id="statement-country"
              name="statement-country"
              label={inventory.messages.country}
              options={filters?.country_ids?.find(item => item?.value === "all") ? [] : lookups.countries}
              placeholder={filters.country_ids}
              onChange={(values, { action }) => {
                if (action === "remove-value" && !values) {
                  setFilters({ ...filters, country_ids: [] });
                  return;
                }
                if (values?.find(item => item?.value === "all")) {
                  setFilters({ ...filters, country_ids: [allItem] })
                  return;
                }
                setFilters({ ...filters, country_ids: values })
              }}
            />
          </div>
          {/* trip type */}
          <div className="col-12 col-md-4 col-lg-2 multi-select">
            <SelectField
              multi
              id="statement-triptype"
              name="statement-triptype"
              label={operationStatement.TripType}
              options={filters?.trip_types?.find(item => item?.value === "all") ? [] : lookups.tripTypes}
              placeholder={filters.trip_types}
              onChange={(values, { action }) => {
                if (action === "remove-value" && !values) {
                  setFilters({ ...filters, trip_types: [] });
                  return;
                }
                if (values?.find(item => item?.value === "all")) {
                  setFilters({ ...filters, trip_types: [allItem] })
                  return;
                }
                setFilters({ ...filters, trip_types: values })
              }}
            />
          </div>
          {/* Departure Date */}
          <div className="col-12 col-md-4 col-lg-2">
            <DateRangeField
              label={operationStatement.DepartureDate}
              id="statement-departure-date"
              name="statement-departure-date"
              startDatePlaceholder={productsBuilder.startDatePlaceholder}
              endDatePlaceholder={productsBuilder.EndDatePlaceholder}
              startDate={filters?.departure_dates?.[0]}
              endDate={filters?.departure_dates?.[1]}
              onDatesChange={({ startDate, endDate }) => {
                endDate = endDate
                  ? moment(endDate).format("YYYY-MM-DD")
                  : moment(startDate).add(1, "d").format("YYYY-MM-DD");
                setFilters({
                  ...filters,
                  departure_dates: [moment(startDate), endDate]
                });
              }}
              isOutsideRange={(day) => {
                return false;
              }}
              numberOfMonths={1}
            />

          </div>
          {/* Return Date */}
          <div className="col-12 col-md-4 col-lg-2">
            <DateRangeField
              label={operationStatement.ArrivalDate}
              id="statement-return-date"
              name="statement-return-date"
              startDatePlaceholder={productsBuilder.startDatePlaceholder}
              endDatePlaceholder={productsBuilder.EndDatePlaceholder}
              startDate={filters?.arrival_dates?.[0]}
              endDate={filters?.arrival_dates?.[1]}
              onDatesChange={({ startDate, endDate }) => {
                endDate = endDate
                  ? moment(endDate).format("YYYY-MM-DD")
                  : moment(startDate).add(1, "d").format("YYYY-MM-DD");
                setFilters({
                  ...filters,
                  arrival_dates: [moment(startDate), endDate]
                });
              }}
              isOutsideRange={(day) => {
                return false;
              }}
              numberOfMonths={1}
            />
          </div>
          {/* hotel city */}
          <div className="col-12 col-md-4 col-lg-2 multi-select">
            <SelectField
              multi
              id="statement-hotel-city"
              name="statement-hotel-city"
              label={operationStatement.HotelCity}
              options={filters?.hotel_cities?.find(item => item?.value === "all") ? [] : lookups.hotelCitiesLookup}
              placeholder={filters?.hotel_cities}
              onChange={(values, { action }) => {
                if (action === "remove-value" && !values) {
                  setFilters({ ...filters, hotel_cities: [] });
                  return;
                }
                if (values?.find(item => item?.value === "all")) {
                  setFilters({ ...filters, hotel_cities: [allItem] })
                  return;
                }
                setFilters({ ...filters, hotel_cities: values })
              }}
            />
          </div>
          {/* checkin date*/}
          <div className="col-12 col-md-4 col-lg-2">
            <DateRangeField
              label={operationStatement.CheckinDate}
              id="statement-checkin-date"
              name="statement-checkin-date"
              startDatePlaceholder={productsBuilder.startDatePlaceholder}
              endDatePlaceholder={productsBuilder.EndDatePlaceholder}
              startDate={filters?.check_in_dates?.[0]}
              endDate={filters?.check_in_dates?.[1]}
              onDatesChange={({ startDate, endDate }) => {
                endDate = endDate ? moment(endDate) : moment(startDate).add(1, "d");
                setFilters({
                  ...filters,
                  check_in_dates: [moment(startDate), endDate]
                });
              }}
              isOutsideRange={(day) => {
                return false;
              }}
              numberOfMonths={1}
            />

          </div>
          {/* checkout date */}
          <div className="col-12 col-md-4 col-lg-2">
            <DateRangeField
              label={operationStatement.CheckoutDate}
              id="statement-checkout-date"
              name="statement-checkout-date"
              startDatePlaceholder={productsBuilder.startDatePlaceholder}
              endDatePlaceholder={productsBuilder.EndDatePlaceholder}
              startDate={filters?.check_out_dates?.[0]}
              endDate={filters?.check_out_dates?.[1]}
              onDatesChange={({ startDate, endDate }) => {
                endDate = endDate ? moment(endDate) : moment(startDate).add(1, "d");

                setFilters({
                  ...filters,
                  check_out_dates: [moment(startDate), endDate]
                });
              }}
              isOutsideRange={(day) => {
                return false;
              }}
              numberOfMonths={1}
            />
          </div>
          {/* Transporter */}
          <div className="col-12 col-md-4 col-lg-2  multi-select">
            <label htmlFor="" className='mb-1 control-field__label'>{operationStatement.Transporter}</label>
            <MultiSelect
              id="statement-transporter"
              name="statement-transporter"
              isMulti
              filterOptions={filterTransporterOptions}
              options={options}
              value={filters?.transporter_ids}
              onChange={(e) => {
                setFilters({ ...filters, transporter_ids: e })
              }}
              valueRenderer={valueRenderer}
            />
          </div>

          <div className='d-flex align-items-center'>
            <button 
              className='btn bg-nxt search-btn mx-2'
              onClick={() => {
                fetchStatementList()
                setIsFilterOpen(false)
              }}
            >
              <SearchIcon />
              <span className='mx-2'>{marketPlace.messages.search}</span>
            </button>

            <button 
              className='btn reset-btn' 
              onClick={() => {
                resetFilters()
                setIsFilterOpen(false)
              }}
            >
              <ResetFilterIcon />
              <span className='mx-2'>{payment.messages.clear}</span>
            </button>
          </div>


        </div>
      </div>
    </>
  )
}
