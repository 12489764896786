import PackageCard from 'modules/WebBuilder-V2/Components/Content/OurPackages/PackageCard';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import { AddElement } from 'modules/WebBuilder-V2/shared/AddElement';
import AddService from 'modules/WebBuilder-V2/shared/AddService';
import CustomLeftArrow from 'modules/WebBuilder-V2/shared/CustomLeftArrow';
import CustomRightArrow from 'modules/WebBuilder-V2/shared/CustomRightArrow';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import React, { useState } from 'react'
import Carousel from 'react-multi-carousel';
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import PackageCardwithImage from 'modules/WebBuilder-V2/Components/Content/OurPackages/PackageCardwithImage';
import { useWebBuilderDispatch } from 'context/webBuilder';
import Locale from 'translations';

export default function CustomPagePackage({ details, pageId, title, focusContainer, onFocus, preview, uuid }) {
  const { id, items, hasSlider, index } = details;
  const { webBuilder } = Locale;
  const dispatch = useWebBuilderDispatch();

  const [openAdd, setOpenAdd] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);

  function handleOpenAddModal() {
    setOpenAdd((prev) => !prev);
  };


  const openEditModal = (item) => {
    handleOpenAddModal();
    setUpdateItem(item);
  };


  const editItem = (item, index) => {
    return [
      <span
        key={`${item.id}-${index}`}
        onClick={() => openEditModal({ ...item, index })}
        className="pointer-event"
      >
        <SettingIcon />
      </span>,
    ];
  };

  function deleteBodyContent(itemId) {
    dispatch({
      type: "deleteItemFromCustomPage",
      payload: {
        sectionId: id,
        itemId,
        pageId
      },
    });
  };
  function itemsBoxActions(itemId) {
    return [
      <span className="pointer-event" key={itemId}
        onClick={() => deleteBodyContent(itemId)}
      >
        <TrashIcon />
      </span>
    ];
  };

  function addItemToPackages(item) {
    updateItem
      ? dispatch({
        type: "editItemToCustomPage",
        payload: {
          sectionIndex: index,
          item,
          itemIndex: updateItem.index,
          pageId,
          pageUUID: uuid
        },
      })
      : dispatch({
        type: "addItemToCustomPage",
        payload: {
          sectionIndex: index,
          item,
          pageId,
          pageUUID: uuid
        },
      });
    setUpdateItem(null);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1
    }
  };
  const selectedIds = items ? items.map((i) => i.id) : [];

  return (
    <>
      {(items?.length > 0 || !preview) &&
        <h3 className="section-title">
          {title}
        </h3>
      }

      {hasSlider ?
        <EditBox editMode={focusContainer === id} defaultIcon={""}>
          <div className="web-builder-content-our-packages-with-slider p-0"
            onClick={() => onFocus(id)}
          >
            {!preview &&
              <div className="service-top-section">
                <AddElement onClick={handleOpenAddModal} title="Add Packages" />
              </div>
            }
            {items?.length > 0 && (
              <div className="all-web-builder-our-service-carousel">
                <Carousel
                  responsive={responsive}
                  itemClass={"service-slide"}
                  className="web-builder-our-service-carousel"
                  slidesToSlide={1}
                  keyBoardControl={true}
                  customRightArrow={<CustomRightArrow />}
                  customLeftArrow={<CustomLeftArrow />}
                  renderButtonGroupOutside={false}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  infinite={true}
                >
                  {items?.map((props, index) => (
                    <DragAndDropContainer
                      accept={"packagesBlocksType"}
                      type={"packagesBlocksType"}
                      id={props.id}
                      index={index}
                      action={SORT_ITEMS_IN_CONTAINER_ACTION}
                      // moveItemInContainer={(dragIndex, hoverIndex) =>
                      //   sortPackagesItems({ dragIndex, hoverIndex })
                      // }
                      key={props.id}
                    >
                      <EditBox
                        editMode={focusContainer === id}
                        actions={[
                          ...editItem(props, index),
                          ...itemsBoxActions(props.id),
                        ]}
                      >
                        <PackageCard {...props} key={index} openEditModal={openEditModal} />
                      </EditBox>
                    </DragAndDropContainer>
                  ))}
                </Carousel>
              </div>
            )}
          </div>
        </EditBox>
        :
        <EditBox editMode={focusContainer === id}>
          <div id={id} className="web-builder-content-our-packages-with-image"
            onClick={() => onFocus(id)}
          >
            {!preview &&
              <div className="service-top-section">
                <AddElement onClick={handleOpenAddModal} title="Add Packages" />
              </div>
            }

            {items.length > 0 && (
              <div className="all-web-builder-our-service-images">

                {items.map((props, index) => (
                  <DragAndDropContainer
                    accept={"packagesBlocksType"}
                    type={"packagesBlocksType"}
                    id={props.id}
                    index={index}
                    action={SORT_ITEMS_IN_CONTAINER_ACTION}
                    // moveItemInContainer={(dragIndex, hoverIndex) =>
                    //   sortPackagesItems({ dragIndex, hoverIndex })
                    // }
                    key={props.id}
                    className='our-service-item'
                  >
                    <EditBox
                      editMode={focusContainer === id}
                      actions={[
                        ...editItem(props, index),
                        ...itemsBoxActions(props.id),
                      ]}
                    >
                      <PackageCardwithImage {...props} key={index} openEditModal={openEditModal} />
                    </EditBox>
                  </DragAndDropContainer>
                ))}
              </div>
            )}
          </div>
        </EditBox>
      }

      {openAdd && (
        <AddService
          handleModalState={handleOpenAddModal}
          open={openAdd}
          label={webBuilder.packages}
          header={webBuilder.package}
          onAdd={addItemToPackages}
          details={updateItem}
          selectedIds={selectedIds}
        />
      )}
    </>
  )
}
