import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function UpdateOperationModal(props) {
	const { operationStatement } = Locale;
	const { isOpen, toggle, data, setData, update } = props;
	const { allCountries } = useSBSState();
	const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

	const countries = allCountries?.map((country) => {
		return {
			id: country?.id,
			label: country?.phone_code,
			name: country?.phone_code,
			value: country?.phone_code,
			image: country?.image,
			flag: country?.flag,
		};
	});

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			centered={true}
			hasFooter={false}
			className="operation-statement-numbers-model"
		>
			<ModalHeader toggle={toggle}>
				{operationStatement.UpdateNumbersID}
			</ModalHeader>
			<ModalBody>
				<div className="container p-2 pb-4">
					<div className="row">
						{/* File No */}
						<div className="col-lg-6 col-12">
							<TextField
								label={operationStatement.fileNo}
								type="text"
								name="file_number"
								placeholder={operationStatement.fileNo}
								value={data.file_number}
								onChange={(e) => {
									setData({
										...data,
										file_number: e.target.value,
									});
								}}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 col-12">
							<TextField
								label={operationStatement.credentialNo}
								disabled={data?.allData?.transportation == null}
								type="text"
								name="credential_number"
								value={data.credential_number}
								placeholder={operationStatement.credentialNo}
								onChange={(e) => {
									setData({
										...data,
										credential_number: e.target.value,
									});
								}}
							/>
						</div>
						<div className="col-lg-6 col-12">
							<TextField
								disabled={data?.allData?.transportation == null}
								label={operationStatement.operationID}
								type="text"
								name="operation_number"
								value={data.operation_number}
								placeholder={operationStatement.operationID}
								onChange={(e) => {
									setData({
										...data,
										operation_number: e.target.value,
									});
								}}
							/>
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-lg-6 col-12">
							<TextField
								label={operationStatement.Supervisor}
								type="text"
								name="supervisor_name"
								placeholder={operationStatement.Supervisor}
								value={data.supervisor_name}
								onChange={(e) => {
									setData({
										...data,
										supervisor_name: e.target.value,
									});
								}}
							/>
						</div>
						<div className="col-lg-6 col-12">
							<div className="row mx-0 align-items-end gap-1/2">
								{/* phone code */}
								<div className="col-12 col-md-4 p-0" style={{flex: 1}}>
									<SelectField
										label={operationStatement.PhoneNumber}
										name={"phone_code"}
										// value={<img src={`${fetchFlag}/${data.phone_code?.image}`} alt="" width={45} height={22}/>}
										// value={data.phone_code?.label}
										value={
											data.phone_code?.flag ?
												<img src={`${fetchFlag}/${data.phone_code?.flag}`} width="30px" alt='' />
												:
												data.phone_code?.label
										}
										onChange={(e) => {
											setData({
												...data,
												phone_code: e,
											});
										}}
										options={countries}
									/>
								</div>
								{/* phone */}
								<div className="col-12 col-md-8 px-0">
									<NumberField
										type={"phone"}
										hasLabel={false}
										name="phoneNumber"
										id="phoneNumber"
										value={data.phone_number}
										removeArrow={true}
										placeholder={"000 0000 000"}
										min={3}
										onChange={(e) => {
											setData({
												...data,
												phone_number: e.target.value,
											});
										}}
										extraTextPosition="prepend"
										extraText={data.phone_code?.value}
									/>
								</div>
							</div>
						</div>
					</div>

					<button
						className="btn mt-3  w-100 "
						onClick={update}
						style={{
							backgroundColor: "#DAA249",
							fontSize: "18px",
							color: "#FFFFFF",
						}}
						// disabled={!newApplicationData.adultsPax}
					>
						{operationStatement.Update}
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
}
