import axios from "axios";
import { cleanEmpty } from "./general";

const URL = process.env.REACT_APP_API_URL + "/v1";
const operationStatementURL = URL + "/operation-statement";
const operationStatementTripURL = process.env.REACT_APP_API_URL + "/trip-management/operation-statement";

export const addNewAgent = async (data) => {
	return await axios.post(`${operationStatementURL}/agents/create`, data);
};
export const viewAgent = async (id) => {
	return await axios.get(`${operationStatementURL}/agents/show/${id}`);
};
export const updateAgent = async (id, data) => {
	return await axios.post(`${operationStatementURL}/agents/edit/${id}`, data);
};

export const generateMovements = async (id) => {
	return await axios
		.get(
			`${operationStatementURL}/statements/${id}/transportations/generate-movements`
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getSegments = async (id, city_id, destination) => {
	return await axios
		.get(
			`${operationStatementURL}/statements/${id}/transportations/get-segments?city_id=${city_id}&type=${destination}`
		)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getAgentList = async (filters) => {
	return await axios.get(`${operationStatementURL}/agents`, {
		params: cleanEmpty(filters),
	});
};

export const getStatementsList = async (filters) => {
	return await axios.get(`${operationStatementURL}/statements`, {
		params: cleanEmpty(filters),
	});
};

export const addEditAttractions = async (id, data) => {
	return await axios.post(
		`${operationStatementURL}/statements/${id}/attractions`,
		data
	);
};

export const addEditTransportation = async (id, data) => {
	return await axios.post(
		`${operationStatementURL}/statements/${id}/transportations`,
		data
	);
};

export const addEditVisa = async (id, data) => {
	return await axios.post(
		`${operationStatementURL}/statements/${id}/visa`,
		data
	);
};

export const addEditOtherServices = async (id, data) => {
	return await axios.post(
		`${operationStatementURL}/statements/${id}/other-services`,
		data
	);
};
export const attractionsNamesLookup = async (city_id) => {
	return await axios.get(`${URL}/lookups/attractions`, {
		params: cleanEmpty(city_id),
	});
};

export const addNewStatements = async (data) => {
	return await axios.post(`${operationStatementURL}/statements`, data);
};
export const addNewStatementsPublic = async (id, data) => {
	return await axios.post(
		`${operationStatementURL}/company-update-statements/${id}`,
		data
	);
};

export const updateNewStatements = async (id, data) => {
	return await axios.put(`${operationStatementURL}/statements/` + id, data);
};

export const addTripStatements = async (id, data) => {
	return await axios.post(
		`${operationStatementURL}/statements/` + id + "/trip",
		data
	);
};
export const addHotelStatements = async (id, data) => {
	return await axios.post(
		`${operationStatementURL}/statements/` + id + "/hotels",
		data
	);
};

export const addCateringStatements = async (id, data) => {
	return await axios.post(
		`${operationStatementURL}/statements/` + id + "/catering",
		data
	);
};

export const getOperationStatement = async (ID) => {
	return await axios.get(`${operationStatementURL}/statements/` + ID);
};



export const fetchHotels = async (countryID, cityId, search) => {
	if (countryID !== 0) {
		return await axios
			.get(
				`${URL}/marketplace/lookups/hotels?country_id=${countryID}&city_id=${cityId}${search ? `&search=${search}` : ""
				}`
			)
			.then((res) => res.data)
			.catch((err) => err.response);
	}
};

export const updateOperationStatement = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/statements/` + id + "/update-numbers", data)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const sendToCompany = async (data) => {
	return await axios
		.post(`${operationStatementURL}/statements/send-to-company`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const getAgentsLookup = async () => {
	return await axios.get(`${operationStatementURL}/agents/lookup`);
};
export const fetchMultiTransporters = async (params) => {
	return await axios
		.get(`${URL}/lookups/transporters`, { params: cleanEmpty(params) })
		.then((res) => res.data.data)
		.catch((err) => err.response);
};
export const changeStatementStatus = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/statements/${id}/change-status`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const agentExportToExcel = async (params) => {
	return await axios
		.get(`${operationStatementURL}/agents/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const operationStatementExportToExcel = async (params) => {
	return await axios
		.get(`${operationStatementURL}/statements/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const exportExcelSingleOperation = async (id) => {
	return await axios
		.post(`${operationStatementURL}/statements/${id}/export`, {},
			{
				responseType: "blob",
			}
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchTimingSettings = async () => {
	return await axios
		.get(`${operationStatementURL}/timing-settings`)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};
export const addTimingSettings = async (data) => {
	return await axios
		.post(`${operationStatementURL}/timing-settings`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const getBasicSettings = async () => {
	return await axios
		.get(`${operationStatementURL}/basic-settings`)
		.then((res) => res)
		.catch((err) => err);
};
export const addBasicSettings = async (data) => {
	return await axios
		.post(`${operationStatementURL}/basic-settings`, data)
		.then((res) => res)
		.catch((err) => err);
};
export const updateBasicSettings = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/basic-settings/${id}`, data)
		.then((res) => res)
		.catch((err) => err);
};
export const setSerialCode = async (data) => {
	return await axios
		.post(`${operationStatementURL}/basic-settings/set-serial-code`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const fetchDelegarationReport = async (filters) => {
	return await axios.get(
		`${operationStatementURL}/movements/delegation-report`,
		{
			params: cleanEmpty(filters),
		}
	);
};

export const checkUserBasicSettings = async () => {
	return await axios
		.get(`${operationStatementURL}/basic-settings/has-basic-settings`)
		.then((res) => res)
		.catch((err) => err);
};

export const getMovements = async (filters) => {
	return await axios
		.get(`${operationStatementURL}/movements`, { params: cleanEmpty(filters) })
		.then((res) => res)
		.catch((err) => err);
};
export const updateMovmentSupervisor = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/movements/${id}/update-supervisor`, data)
		.then((res) => res)
		.catch((err) => err);
};
export const updateMovmentOfficer = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/movements/${id}/update-officer`, data)
		.then((res) => res)
		.catch((err) => err);
};
export const updateMovmentDrivers = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/movements/${id}/update-drivers`, data)
		.then((res) => res)
		.catch((err) => err);
};
export const getDriversLookup = async (params) => {
	return await axios
		.get(`${operationStatementURL}/lookup/drivers`, { params: cleanEmpty(params) })
		.then((res) => res)
		.catch((err) => err);
};

export const executeMovment = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/movements/${id}/execute`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const exportRawadReservationsExcel = async (filters) => {
	return await axios
		.get(`${operationStatementURL}/rawda-reservations/export`, {
			params: cleanEmpty(filters),
		})
		.then((res) => res)
		.catch((err) => err);
};
export const getRawadReservations = async (filters) => {
	return await axios
		.get(`${operationStatementURL}/rawda-reservations`, {
			params: cleanEmpty(filters),
		})
		.then((res) => res)
		.catch((err) => err);
};


export const executeRawadReservations = async (id) => {
	return await axios
		.get(`${operationStatementURL}/rawda-reservations/${id}/execute`)
		.then((res) => res)
		.catch((err) => err);
};

export const getListOperation = async (filters = {}) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements`, {
			params: cleanEmpty(filters),
		})
		.then((res) => res)
		.catch((err) => err);
};

export const listOperationExportToExcel = async (params) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

/** Get Accounting Statement */
export const getAccountingStatements = async (id) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${id}`)
		.then((res) => res)
		.catch((err) => err);
};

export const getAccountingAgents = async (params) => {
	return await axios
		.get(`${operationStatementURL}/accounting/agents/violations`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err);
}

export const getAccountingView = async (id) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${id}`)
		.then((res) => res)
		.catch((err) => err);
};

export const updateArrivalPricing = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/accounting/statements/${id}/trip`, data)
		.then((res) => res)
		.catch((err) => err);
};
export const updateTransportationPricing = async (id, data) => {
	return await axios
		.post(
			`${operationStatementURL}/accounting/statements/${id}/transportations`,
			data
		)
		.then((res) => res)
		.catch((err) => err);
};
export const updateAttractionsPricing = async (id, data) => {
	return await axios
		.post(
			`${operationStatementURL}/accounting/statements/${id}/attraction`,
			data
		)
		.then((res) => res)
		.catch((err) => err);
};
export const updateVisaPricing = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/accounting/statements/${id}/visa`, data)
		.then((res) => res)
		.catch((err) => err);
};
export const updateOhterServicesPricing = async (id, data) => {
	return await axios
		.post(
			`${operationStatementURL}/accounting/statements/${id}/other-services`,
			data
		)
		.then((res) => res)
		.catch((err) => err);
};

export const fetchAccountingAgentViolation = async (id) => {
	return await axios
		.get(`${operationStatementURL}/accounting/agents/${id}/show-violation`)
		.then((res) => res)
		.catch((err) => err);
}


export const updateStatusOperation = async (payment_status, statementId) => {
	return await axios
		.post(
			`${operationStatementURL}/statements/${statementId}/change-payment-status`,
			{ status: payment_status }
		)
		.then((res) => res)
		.catch((err) => err);
};

export const updatePricingType = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/accounting/statements/${id}/update-pricing-type`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const updateHotelPricing = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/accounting/statements/${id}/hotels`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const updateCateringPricing = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/accounting/statements/${id}/catering`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const saveFullPackagePricing = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/accounting/statements/${id}/save-package-pricing`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const updateTaxType = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/accounting/statements/${id}/update-tax-type`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const visaInvoice = async (accountingId) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${accountingId}/visa-invoice`)
		.then((res) => res)
		.catch((err) => err);
}

export const otherServicesInvoice = async (accountingId) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${accountingId}/other-services-invoice`)
		.then((res) => res)
		.catch((err) => err);
}


export const cateringInvoice = async (accountingId) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${accountingId}/catering-invoice`)
		.then((res) => res)
		.catch((err) => err);
}

export const arrivalInvoice = async (accountingId) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${accountingId}/arrival-departure-invoice`)
		.then((res) => res)
		.catch((err) => err);
}

export const hotelInvoice = async (accountingId) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${accountingId}/hotel-invoice`)
		.then((res) => res)
		.catch((err) => err);
}

export const transportationInvoice = async (accountingId) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${accountingId}/transportation-invoice`)
		.then((res) => res)
		.catch((err) => err);
}

export const attractionInvoice = async (accountingId) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${accountingId}/attractions-invoice`)
		.then((res) => res)
		.catch((err) => err);
}

export const operationInvoice = async (accountingId) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${accountingId}`)
		.then((res) => res)
		.catch((err) => err);
}

export const fetchOperationStatementInvoice = async (id) => {
	return await axios
		.get(`${operationStatementURL}/accounting/statements/${id}/operation-statement`)
		.then((res) => res)
		.catch((err) => err);
}

export const fetchOperationGeneralStatistics = async (params) => {
	return await axios
		.get(`${operationStatementURL}/statistics/general`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err);
}
export const fetchOperationPrivateStatistics = async (params) => {
	return await axios
		.get(`${operationStatementURL}/statistics/private`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err);
}
export const fetchOperationAgentsStatistics = async (params) => {
	return await axios
		.get(`${operationStatementURL}/statistics/agents`, {
			params: cleanEmpty(params),
		})
		.then((res) => res)
		.catch((err) => err);
}
export const UploadTravellerFile = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/statements/${id}/update-travellers-file`, data)
		.then((res) => res)
		.catch((err) => err);
}
// operation-statement/lookup/company-by-licence?licence_key=sdfdsf
export const SendLicenceNumber = async (params) => {
	return await axios
		.get(`${operationStatementURL}/lookup/company-by-licence`, {
			params: (params),
		})
		.then((res) => res)
		.catch((err) => err);
}
export const SendUmrahCompanyDetails = async (reference_number, data) => {
	return await axios
		.post(`${operationStatementURL}/statements/${reference_number}/send-to-umrah-company`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const GenerateHotels = async (reference_number, params) => {
	return await axios
		.get(`${operationStatementURL}/statements/${reference_number}/hotels/generate-hotels`, {
			params: (params),
		})
		.then((res) => res)
		.catch((err) => err);
}
export const GenerateFlights = async (reference_number, params) => {
	return await axios
		.get(`${operationStatementURL}/statements/${reference_number}/trip/generate-flights`, {
			params: (params),
		})
		.then((res) => res)
		.catch((err) => err);
}

export const FetchUmerahRequest = async (params) => {
	return await axios
		.get(`${operationStatementURL}/statements/sent-to-umrah-company`, {
			params: (params),
		})
		.then((res) => res)
		.catch((err) => err);
}
export const getSentOperationStatement = async (id, params) => {
	return await axios.get(`${operationStatementURL}/statements/show-sent-to-umrah-company/${id}`, {
		params: (params),
	});
};

export const fetchOrdersViolations = async (params) => {
	return await axios.get(`${operationStatementURL}/agent-violations`, {
		params: cleanEmpty(params)
	})
		.then((res) => res)
		.catch((err) => err);
}
export const markOrdersViolationPaid = async (id, data) => {
	return await axios.post(`${operationStatementURL}/agent-violations/${id}/pay`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const fetchOrdersViolationById = async (id) => {
	return await axios.get(`${operationStatementURL}/agent-violations/${id}`)
		.then((res) => res)
		.catch((err) => err);
}

export const createOrderViolation = async (data) => {
	return await axios
		.post(`${operationStatementURL}/agent-violations`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const updateOrderViolation = async (id, data) => {
	return await axios
		.put(`${operationStatementURL}/agent-violations/${id}`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const searchOrderViolation = async (text) => {
	return await axios
		.get(`${operationStatementURL}/agent-violations?search=${text}`)
		.then((res) => res)
		.catch((err) => err);
}

export const violationsExportToExcel = async (params) => {
	return await axios
		.get(`${operationStatementURL}/agent-violations/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};
// officers groups
export const fetchOfficersGroupsList = async (params) => {
	return await axios
		.get(`${operationStatementURL}/officer-group`, { params: (cleanEmpty(params)) })
		.then((res) => res)
		.catch((err) => err);
}

export const createGroup = async (data) => {
	return await axios
		.post(`${operationStatementURL}/officer-group`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const updateGroup = async (id, data) => {
	return await axios
		.put(`${operationStatementURL}/officer-group/${id}`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const changeGroupStatus = async (id, data) => {
	return await axios
		.put(`${operationStatementURL}/officer-group/${id}/status`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const deleteGroup = async (id) => {
	return await axios
		.delete(`${operationStatementURL}/officer-group/${id}`)
		.then((res) => res)
		.catch((err) => err);
}

export const fetchOfficersList = async (params) => {
	return await axios
		.get(`${operationStatementURL}/officer`, { params: (cleanEmpty(params)) })
		.then((res) => res)
		.catch((err) => err);
}

export const createOfficer = async (data) => {
	return await axios
		.post(`${operationStatementURL}/officer`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const updateOfficer = async (id, data) => {
	return await axios
		.put(`${operationStatementURL}/officer/${id}`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const unlinkOfficer = async (id) => {
	return await axios
		.delete(`${operationStatementURL}/officer/${id}`)
		.then((res) => res)
		.catch((err) => err);
}
// guide requests
export const fetchGuideRequestesList = async (params) => {
	return await axios
		.get(`${operationStatementURL}/guide-requests`, { params: (cleanEmpty(params)) })
		.then((res) => res)
		.catch((err) => err);
}
export const createGuideRequest = async (data) => {
	return await axios
		.post(`${operationStatementURL}/guide-requests`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const updateGuideRequest = async (id, data) => {
	return await axios
		.put(`${operationStatementURL}/guide-requests/${id}`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const guideRequestAttachOfficer = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/guide-requests/${id}/attach-officer`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const executeGuideRequest = async (id) => {
	return await axios
		.post(`${operationStatementURL}/guide-requests/${id}/execute`)
		.then((res) => res)
		.catch((err) => err);
}

export const fetchGroupsLookup = async () => {
	return await axios
		.get(`${operationStatementURL}/lookup/officer-group`)
		.then((res) => res)
		.catch((err) => err);
}
export const fetchOfficersLookup = async (groupId) => {
	return await axios
		.get(`${operationStatementURL}/lookup/officers?group_id=${groupId}`)
		.then((res) => res)
		.catch((err) => err);
}
export const fetchDelegateCompanies = async (serviceType) => {
	return await axios
		.get(`${URL}/lookups/companies?has_service=${serviceType}`)
		.then((res) => res)
		.catch((err) => err);
}
export const attachOfficer = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/movements/${id}/attach-officer`, data)
		.then((res) => res)
		.catch((err) => err);
}

// delegation managament
export const fetchDelegationsList = async (params) => {
	return await axios
		.get(`${operationStatementURL}/movements/delegations`, { params: (cleanEmpty(params)) })
		.then((res) => res)
		.catch((err) => err);
}

// passports
export const updateCreatePassports = async (statementId, data) => {
	return await axios
		.post(`${operationStatementURL}/statements/${statementId}/travellers`, data)
		.then((res) => res)
		.catch((err) => err);
}


export const fetchExternalUmrahCompaniesList = async () => {
	return await axios
		.get(`${URL}/lookups/external-umrah-companies`)
		.then((res) => res)
		.catch((err) => err);
}


export const sendStatementByEmail = async (statementId, data) => {
	return await axios
		.post(`${operationStatementURL}/statements/send-by-email/${statementId}`, data)
		.then((res) => res)
		.catch((err) => err);
}
export const generateTripFromStatement = async (statementId, data) => {
	return await axios
		.post(`${operationStatementTripURL}/generate-trip/${statementId}`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const editPortHall = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/movements/${id}/edit-port-hall`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const editAlternativeTransportation = async (id, data) => {
	return await axios
		.post(`${operationStatementURL}/movements/${id}/edit-alternative-transporter`, data)
		.then((res) => res)
		.catch((err) => err);
}

export const fetchTripData = async (id) => {
	return await axios
		.get(`${operationStatementURL}/statements/${id}`)
		.then((res) => res)
		.catch((err) => err);
}

export const uploadInvoice = async (statementId, data) => {
	return await axios
		.post(`${operationStatementURL}/accounting/statements/${statementId}/update-service-invoices`, data)
		.then((res) => res)
		.catch((err) => err);
};


export const fetchAllOfficersLookup = async (search = '') => {
	return await axios
		.get(`${operationStatementURL}/lookup/officers${search}  `)
		.then((res) => res)
		.catch((err) => err);
}

export const movementManagementExportToExcel = async (params) => {
	return await axios
		.get(`${operationStatementURL}/movements/export`, {
			params: cleanEmpty(params),
			responseType: "blob",
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const addMovementByDelegateCompany = async (data) => {
	return await axios
		.post(`${operationStatementURL}/movements/add`, data )
		.then((res) => res)
		.catch((err) => err.response);
};
// *********************** nusuk
export const setNusukCredentials = async (credData) => {
	return await axios
		.post(`${operationStatementURL}/settings/set-nusuk-credentials`, credData)
		.then((res) => res)
		.catch((err) => err);
};

export const companyHasNusukCredentials = async () => {
	return await axios
		.get(`${operationStatementURL}/settings/has-nusuk-credentials`)
		.then((res) => res)
		.catch((err) => err);
};
export const getNusukGroups = async (searchText) => {
	return await axios
		.get(`${operationStatementURL}/fetch-nusuk-groups${searchText ? `?text=${searchText}` : ""}`)
		.then((res) => res)
		.catch((err) => err);
};
export const getNusukGroupsPassports = async (group_id) => {
	return await axios
		.get(`${operationStatementURL}/get-nusuk-group?group_id=${group_id}`)
		.then((res) => res)
		.catch((err) => err);
};

export const createStatemenetWithNusuk = async (data) => {
	return await axios
		.post(`${operationStatementURL}/statements-create-with-nusuk`, data)
		.then((res) => res)
		.catch((err) => err);
};
// *********************** end nusuk
