import React, { useState } from 'react'
import Locale from 'translations';
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import moment from 'moment';
import { CheckMarkIcon, DocumentDownloadIcon } from 'modules/UmrahOperations/shared/Icons';
import { useSBSState } from 'context/global';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import axios from 'axios';
import ShowForPermission from 'helpers/showForPermission';


const filesUrl = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function DelegationManagementTableRow({
  movmentItem,
  toggleOfficerModal,
  toggleExecuteModal,
  handleExecuteMovement,
  toggleHallModal,
  toggleTransportationModal
}) {
  const { operationStatement, movementManagement, productElements } = Locale;
  const { companyInfo } = useSBSState();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isServiceCompany = companyInfo?.company_category === "services_company";




  function toggle() {
    setDropdownOpen((prevState) => !prevState);
  }

  function defineColor(type) {
    if (type === 'executed') {
      return '#019d4c'
    }
    if (type === 'pending') {
      return '#E56910'
    }
    if (type === 'in_progress') {
      return '#4C9AFF'
    }
    if (type === 'assigned_to_company') {
      return '#6554C0'
    }
    if (type === 'rejected') {
      return 'red'
    }
  }

  function defineStatusText(type) {
    if (type === 'executed') {
      // return 'Executed'
      return productElements?.EXecuted 
    }
    if (type === 'pending') {
      // return 'Pending'
      return productElements?.pending 
    }
    if (type === 'in_progress') {
      // return 'In progress'
      return productElements?.in_progress
    }
    if (type === 'assigned_to_company') {
      // return 'Assigned to company'
      return productElements?.assigned_to_company
    }
    if (type === 'rejected') {
      // return 'Rejected'
      return productElements?.rejected
    }
  }

  const handleDownload = async (fileUUID) => {
    const config = {
      method: "GET",
      responseType: "blob",
    };
    const res = await axios.get(`${process.env.REACT_APP_FILEUPLOAD_URL}/fetch/${fileUUID}`, config);
    const blob = new Blob([res.data], {
      type: res.headers["content-type"],
    });

    const objectUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.target = "_blank";
    anchor.href = objectUrl;
    anchor.setAttribute(
      "download",
      `attach-file-${moment().format("DD-MM-YYYY")}.${res.data.type.split("/")[1]}`
    );
    anchor.click();

    handleResponesNotification({ alertType: "success", message: "Downloaded Successfully", title: "Download" })
  };

  function movementTypesText(type) {
    switch (type) {
      case "arrival":
        return operationStatement.Arrival
      case "departure":
        return operationStatement.Departure
      case "attraction":
        return operationStatement.mazar
      case "internal_movement":
        return operationStatement.transfer
      default:
        break;
    }
  }

  const isNotContainsFiles = !movmentItem?.attach_file && !movmentItem?.entrance_stamp && !movmentItem?.bus_image;


  return (
    <tr>
      {/* type */}
      <td className={movmentItem?.type}>
        <div className='d-flex align-items-center'>
          <span className='indecator'></span>
          <div className='d-flex flex-column mx-2'>
            <span className='text-capitalize'>{movementTypesText(movmentItem?.type)}  </span>
            <span># {movmentItem?.company_statement_reference}</span>
          </div>
        </div>
      </td>

      {/* agent */}
      <td width="115px">
        <p>{movmentItem?.agent?.name}</p>
        <div className="d-flex align-items-center">
          <img
            src={movmentItem?.agent?.country?.flag ? `${filesUrl}/${movmentItem?.agent?.country?.flag}` : "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E"}
            alt={movmentItem?.agent?.country?.name}
            className={`rounded-circle ${!movmentItem?.agent?.country?.flag ? " border" : ""}`}
            width={"16px"}
            height={"16px"}
            style={{ flex: "0 0 16px" }}
          />
          <span className="font-light mx-1">
            {movmentItem?.agent?.country?.name}
          </span>
        </div>
      </td>

      {/* Supervisor */}
      <td>

        <p className='d-flex flex-nowrap'>
          <span>{movmentItem?.supervisor?.name}</span>
        </p>
        <p className='font-light direction-initial'>
          {movmentItem?.supervisor?.phone_code}{movmentItem?.supervisor?.phone}
        </p>

        {movmentItem?.nusuk ?
          <p>
            <span className='font-light' style={{ color: "#FF9F43" }}>{operationStatement.nusuk}</span>
            <span className='font-light'>: {movmentItem?.nusuk}</span>
          </p>
          :
          "-"
        }

      </td>

      {/* pax */}
      <td>{movmentItem?.pax}</td>

      {/* From */}
      <td className='from-color' width="260px">
        {movmentItem?.pickupPoints && movmentItem?.pickupPoints?.length > 0 ?
          movmentItem?.pickupPoints?.map((point, index) => {
            return (
              <div key={`${point?.id}-${point?.item_type}`}>
                <p className='font-light text-capitalize'>{point?.city?.name || point?.port_type}</p>
                <p>{point?.title}</p>
                {(point?.port_type === 'Airport' || point?.port_type === 'مطار' ) &&
                  <p>
                    {productElements.hall} : {point?.port_hall?.name}
                    <button
                      className='btn p-0'
                      onClick={() => toggleHallModal({
                        movementId: movmentItem?.id,
                        type: 'pickupPoints',
                        index: index,
                        hall: point?.port_hall
                      })}>
                      <EditIcon width="16px" height="16px" />
                    </button>
                  </p>
                }
              </div>
            )
          })
          :
          "-"
        }

      </td>

      {/* to */}
      <td className='to-color' width="260px">
        {movmentItem?.destinations && movmentItem?.destinations?.length > 0 ?
          movmentItem?.destinations?.map((point, index) => {
            return (
              <div key={`${point?.id}-${point?.item_type}`}>
                <p className='font-light'>{point?.city?.name || point?.port_type}</p>
                <p>{point?.title}</p>
                {(point?.port_type === 'Airport' || point?.port_type === 'مطار' ) &&
                  <p>
                    {productElements.hall} : {point?.port_hall?.name}
                    <button
                      className='btn p-0'
                      onClick={() => toggleHallModal({
                        movementId: movmentItem?.id,
                        type: 'destinations',
                        index: index,
                        hall: point?.port_hall
                      })}>
                      <EditIcon width="16px" height="16px" />
                    </button>
                  </p>
                }
              </div>
            )
          })
          :
          "-"
        }
      </td>

      {/* trip number */}
      <td width="260px">
        {movmentItem?.trip_data?.length > 0 ?
          movmentItem?.trip_data?.map((trip) => <p key={trip?.trip_number}>{trip?.trip_number}</p>)
          :
          '-'
        }
      </td>

      {/* transportation company name */}
      <td width="260px">
        {movmentItem?.transporter_company_name ?
          movmentItem?.transporter_company_name
          :
          '-'
        }
      </td>

      <td width="260px">
        {movmentItem?.alternative_transporter?.name ?
          <p>
            {movmentItem?.alternative_transporter?.name}
            <button
              className='btn p-0'
              onClick={() => toggleTransportationModal({ movementId: movmentItem?.id, transporter: movmentItem?.alternative_transporter })}
            >
              <EditIcon width="16px" height="16px" />
            </button>
          </p>
          :
          <button
            className='btn p-0'
            onClick={() => toggleTransportationModal({ movementId: movmentItem?.id, transporter: movmentItem?.alternative_transporter })}
          >
            <EditIcon width="16px" height="16px" />
          </button>
        }
      </td>

      {/* Vehicle's drivers */}
      <td>
        <div className='d-flex justify-content-between align-items-center gap-24'>
          {/* drivers list */}
          <div className='d-flex flex-column mx-2'>
            {movmentItem?.drivers?.map((driver, index) => {
              return (
                <div className='d-flex align-items-baseline justify-content-between mb-2' key={`${driver?.id}-${index}`} >
                  <p className='d-flex flex-column mx-1'>
                    <span>{driver?.name}</span>
                    <span className='font-light direction-initial'>{driver?.phone_code}{driver?.phone}</span>
                  </p>
                </div>
              )
            })}
          </div>

        </div>
      </td>


      {/* requested company */}
      <td>
        {isServiceCompany ?
          (movmentItem?.original_company_name || "-")
          :
          <div className="d-flex align-items-baseline mb-2">
            <div>
              <p>{(movmentItem?.delegation_company_name || "-")}</p>
              <p>{movmentItem?.assigned_to_company_at ? moment(movmentItem?.assigned_to_company_at).utc().format("HH:mm") : "-"} </p>
              <p> {movmentItem?.assigned_to_company_at ? moment(movmentItem?.assigned_to_company_at).format("DD-MM-YYYY") : "-"}</p>
            </div>


            {movmentItem?.delegation_company_name &&
              <ShowForPermission permission="Manage-Delegation-Management">
                <button className="btn add-official-btn"
                  onClick={() => toggleOfficerModal("company", movmentItem, movmentItem?.id, movmentItem?.type)}
                >
                  <EditIcon width="16px" height="16px" />
                </button>
              </ShowForPermission>
            }
          </div>
        }
      </td>



      {/* Movement official */}
      <td>
        {movmentItem?.group ?
          <div>
            <p className='d-flex flex-column font-weight-bold'>
              <span>{movmentItem?.group?.name}</span>

            </p>
            <p>{movmentItem?.assigned_to_group_at ? moment(movmentItem?.assigned_to_group_at).utc().format("HH:mm") : "-"} </p>
            <p> {movmentItem?.assigned_to_group_at ? moment(movmentItem?.assigned_to_group_at).format("DD-MM-YYYY") : "-"}</p>
          </div>
          : null
        }
        <div className='d-flex align-items-baseline mb-2'>


          {/* if request assigend officer */}
          {movmentItem?.officer ?
            <>
              <div>
                <p className='d-flex flex-column me-1'>
                  <span>{movmentItem?.officer?.name}</span>
                  <span className='font-light  direction-initial'>
                    {movmentItem?.officer?.phone_code}{movmentItem?.officer?.phone}
                  </span>
                </p>
                <p>{movmentItem?.assigned_to_officer_at ? moment(movmentItem?.assigned_to_officer_at).utc().format("HH:mm") : "-"} </p>
                <p> {movmentItem?.assigned_to_officer_at ? moment(movmentItem?.assigned_to_officer_at).format("DD-MM-YYYY") : "-"}</p>
              </div>


              {(!movmentItem?.delegation_company_name || isServiceCompany) &&
                <ShowForPermission permission="Manage-Delegation-Management">
                  <button className='btn p-0'
                    onClick={() => toggleOfficerModal("edit", movmentItem, movmentItem?.id, movmentItem?.type)}
                  >
                    <EditIcon width="16px" height="16px" />
                  </button>
                </ShowForPermission>
              }

            </>
            : null
          }

          {/* if request assigend to company */}
          {(movmentItem?.delegation_company_name || movmentItem?.group) && !movmentItem?.officer ?
            <div className="d-flex align-items-center">
              <span>{movmentItem?.status !== 'rejected' && Locale.guideRequests.waitingToAssignOfficer}</span>
              {isServiceCompany &&
                <ShowForPermission permission="Manage-Delegation-Management">
                  <button className="btn add-official-btn"
                    onClick={() => toggleOfficerModal("edit", movmentItem, movmentItem?.id, movmentItem?.type)}
                  >
                    <EditIcon width="16px" height="16px" />
                  </button>
                </ShowForPermission>
              }
            </div>
            :
            null
          }
          {/* if there is no officer and company assigend to request */}
          {(!movmentItem?.officer && !movmentItem?.group && !movmentItem?.delegation_company_name) || movmentItem?.status === 'rejected' ?
            <ShowForPermission permission="Manage-Delegation-Management">
              <button className="btn px-0 add-official-btn"
                onClick={() => toggleOfficerModal("add", movmentItem?.officer, movmentItem?.id, movmentItem?.type)}
              >
                {Locale.guideRequests.addOfficial}
                <EditIcon width="16px" height="16px" className="mx-2" />
              </button>
            </ShowForPermission>
            :
            null
          }


        </div>
      </td>

      {/* status */}
      <td>
        <p
          className='font-bold'
          style={{ color: defineColor(movmentItem?.status) }}
        >
          {defineStatusText(movmentItem?.status)}
        </p>
      </td>

      <td>
        <span className="text-grey-500" style={{ fontSize: "14px" }}>
          {movmentItem?.start_date ? moment(movmentItem?.start_date).format("HH:mm") : "-"}
        </span>
        <br />
        <span className="text-black-900">
          {movmentItem?.start_date ? moment(movmentItem?.start_date).format("DD-MM-YYYY") : "-"}
        </span>

      </td>

      {/* duration */}
      <td>{movmentItem?.duration ? movmentItem?.duration : '-'}</td>

      {/* Action */}
      <td>
        <div className="table-actions justify-content-end">
          {(movmentItem?.type === "arrival" || movmentItem?.type === "departure") && !isNotContainsFiles ?
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle style={{ all: "unset" }}>
                <DocumentDownloadIcon color="#707170" />
              </DropdownToggle>
              <DropdownMenu positionFixed>
                <DropdownItem onClick={() => handleDownload(movmentItem?.attach_file)}>
                  {movementManagement.TAFWEEJ}
                </DropdownItem>
                {movmentItem?.entrance_stamp &&
                  <DropdownItem onClick={() => handleDownload(movmentItem?.entrance_stamp)}>
                    {movementManagement.entranceStamp}
                  </DropdownItem>
                }
                {movmentItem?.bus_image &&
                  <DropdownItem onClick={() => handleDownload(movmentItem?.bus_image)}>
                    {movementManagement.busImage}
                  </DropdownItem>
                }
              </DropdownMenu>
            </Dropdown>
            : null
          }


          {!movmentItem?.is_executed ?
            <ShowForPermission permission="Manage-Delegation-Management">
              <button className="btn unexcuted-btn"
                onClick={() => {
                  if (movmentItem?.type === "arrival" || movmentItem?.type === "departure") {
                    toggleExecuteModal({ movementId: movmentItem?.id })
                  } else {
                    handleExecuteMovement(null, movmentItem?.id);
                  }
                }}
              >
                <span>{movementManagement.unexecuted}</span>
              </button>
            </ShowForPermission>
            :
            <div>

              <p className="excuted-btn py-2 px-3">
                <CheckMarkIcon color="#fff" />
                <span>{movementManagement.executed}</span>
              </p>
              <p>{movmentItem.executed_at ? moment(movmentItem?.executed_at).utc().format("HH:mm") : "-"}</p>
              <p>{movmentItem.executed_at ? moment(movmentItem?.executed_at).format("DD-MM-YYYY") : "-"}</p>
            </div>
          }

        </div>
      </td>
    </tr>
  )
}
