import { useEffect, useState } from "react";
import { ReactComponent as WathcIconsSrc } from "assets/images/umrah-operations/watch-icon.svg";

export const TimeField = ({ changeValue, label, value, errorMsg,onKeyDown }) => {
	const [time, setTime] = useState({
		hours: "00",
		minutes: "00",
	});

	useEffect(() => {
		// Set the internal state based on the value prop
		if (value && value.hours && value.minutes) {
			setTime({
				hours: value.hours,
				minutes: value.minutes,
			});
		}
	}, [value]);


 
	const handleTime = ({ key, value }) => {
		setTime((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	return (
		<div className={`custom-timing-settings ${errorMsg ? "field-has-error" : ""}`}>
			<label className={`d-block time-label `}>{label}</label>
			<div className="custom-time-field-container">
				<div className={`custom-time-field  ${errorMsg ? "field-has-error" : ""}`}>
					<div className="time-details">
						<input
							placeholder="00"
							name="hours"
							value={time.hours}
							type="number"
							min={0}
							max={23}
							onChange={(e) => {
								let value = e.target.value.padStart(2, "0");
								if (value.length > 2 && value[0] === "0") {
									value = value.slice(1);
								}
								if (+value > 23) {
									value = "00";
								}
								handleTime({ key: "hours", value });
                changeValue(value, time.minutes);
							}}
              onKeyDown={onKeyDown}

						/>
						<small>:</small>
						<input
							placeholder="00"
							name="minutes"
							value={time.minutes}
							type="number"
							min={0}
							max={59}
							onChange={(e) => {
								let value = e.target.value.padStart(2, "0");

								if (value.length > 2 && value[0] === "0") {
									value = value.slice(1);
								}
								if (+value > 59) {
									value = "59";
								}
								handleTime({ key: "minutes", value });
                changeValue(time.hours, value);
							}}
              onKeyDown={onKeyDown}

						/>
					</div>
					<WathcIconsSrc />
				</div>
			</div>
			{errorMsg && (
				<small className={`${errorMsg ? "has-error" : ""}`}>{errorMsg}</small>
			)}
		</div>
	);
};
