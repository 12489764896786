import { useEffect, useState } from "react";
import { ReactComponent as ArrivalIcon } from "assets/images/umrah-operations/arrival-icon.svg";
import { ReactComponent as CheckCircleIcon } from "assets/images/umrah-operations/check-circle.svg";
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import { ReactComponent as EyelIcon } from "assets/images/Tds/eye.svg";
import { ReactComponent as DownloadIcon } from "assets/images/umrah-operations/download-cloud.svg";
import { ReactComponent as MoreIcon } from "assets/images/umrah-operations/more-square.svg";
import { ReactComponent as OperatingAttractionIcon } from "assets/images/umrah-operations/attraction-icon.svg";
import { ReactComponent as OperatingOtherIcon } from "assets/images/umrah-operations/other-services-icon.svg";

import CategoryCard from "../shared/CategoryCard";
import {
	CircleArrowLeft,
	CircleArrowRight,
	FlightIcon,
	HotelIcon,
	SettingIcon,
	TransportationIcon,
	DepartureIcon,
	VisaIcon,
	SendIcon,
	ExportToPdf,
	PlusIcon,
} from "../shared/Icons";
import { useDetectClickOutside } from "react-detect-click-outside";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import {
	UploadTravellerFile,
	updateOperationStatement,
	FetchUmerahRequest,
	exportExcelSingleOperation
} from "services/operationStatement";
import { store } from "react-notifications-component";
import UpdateOperationModal from "./modals/UpdateOperationModal";
import SendToCompanyModal from "./modals/sendToCompanyModal";
import Locale from "translations";
import { useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import usePrintingInvoices from "../Accounting/usePrintingInvoices";
import UploadTravellerFileModal from "./modals/UploadTravellerFileModal";
import SendToUmrahCompanyModal from "./modals/SendToUmrahCompanyModal";
import Pagination from 'components/Pagination';
import ConvertStatementToTripModal from './modals/ConvertStatementToTrip';
import OperatingNewSerialModal from './modals/OperatingNewSerialModal';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export default function OperationStatementList({
	statementsList,
	statusCount,
	filters,
	setFilters,
	setReload,
	reload,
	agentsList,
	userShouldAddSerial

}) {
	const { operationStatement } = Locale;
	const history = useHistory();
	const { bundle } = useParams();
	const { companyInfo } = useSBSState();
	const [metaData, setMetaData] = useState(null);
	const [filterSend, setFilterSend] = useState({ page: 1 })

	const isUmrahCompany = companyInfo?.company_category === "umrah_company";

	const statusList = [
		{ title: operationStatement.All, id: "all", count: statusCount?.all },
		{
			title: operationStatement.Approved,
			id: "approved",
			count: statusCount?.approved,
		},
		{
			title: operationStatement.Rejected,
			id: "rejected",
			count: statusCount?.rejected,
		},
		{ title: operationStatement.Draft, id: "draft", count: statusCount?.draft },
		{
			title: operationStatement.Operatingreceived,
			id: "new",
			count: statusCount?.new,
		},
		{
			title: operationStatement.Sent,
			id: "sent",
			count: metaData?.total
		},
	];
	const saudiFlagUUID = 'd68c87b9-9a70-4032-8226-aaab6687442a'
	const [activeStatus, setActiveStatus] = useState(filters?.status);


	const [isOpenModal, setIsOpenModal] = useState(false);
	const [selectedOperation, setSelectedOperation] = useState({
		reference_number: "",
		file_number: "",
		operation_number: "",
		credential_number: "",
		supervisor_name: "",
		supervisor_number: "",
		phone_number: "",
		phone_code: {
			flag: saudiFlagUUID,
			label: "+966",
			value: "+966",
		},
	});

	const [isOpenSendCompanyModal, setIsOpenSendCompanyModal] = useState(false);
	const [isOpenTraveller, setIsOpenTraveller] = useState(false);
	const [isOpenUmrahCompany, setIsOpenUmrahCompany] = useState(false);
	const [isOpenConvertToTrip, setIsOpenConvertToTrip] = useState(false);
	const [isCreateSerialModalOpen, setIsCreateSerialModalOpen] = useState(false);

	const [selectedTraveller, setSelectedTraveller] = useState({});
	const [fileTraveller, setFileTraveller] = useState();
	const [currentRefNumber, setCurrentRefNumber] = useState();
	const [UmrahRequest, setUmrahRequest] = useState([])

	const toggle = (data = null) => {
		setSelectedOperation({
			reference_number: data?.reference_number ?? "",
			file_number: data?.file_number ?? "",
			operation_number: data?.transportation?.operation_id ?? "",

			credential_number: data?.transportation?.credential_number ?? "",
			supervisor_name: data?.supervisor_name ?? "",
			supervisor_number: data?.supervisor_number ?? "",
			phone_number: data?.supervisor_number ?? "",
			phone_code: {
				label: data?.supervisor_country?.phone_code
					? data?.supervisor_country?.phone_code
					: "+966",
				value: data?.supervisor_country?.phone_code
					? data?.supervisor_country?.phone_code
					: "+966",
				flag: data?.supervisor_country
					? data?.supervisor_country?.flag
					: saudiFlagUUID,
			},
			allData: data != null ? data : {},
		});
		setIsOpenModal((prev) => !prev);
	};
	// toggle send to company modal
	const toggleCompany = () => setIsOpenSendCompanyModal((prev) => !prev);
	// toggle traveler modal
	const toggleTraveller = (data = null) => {
		setSelectedTraveller(data ? data : null);
		setIsOpenTraveller((prev) => !prev);
	};

	const toggleUmrah = (refNumber) => {
		setCurrentRefNumber(refNumber)
		setIsOpenUmrahCompany((prev) => !prev);
	};
	// toggle convert statement to trip 
	const toggleConvertToTripModal = (statementId) => {
		setCurrentRefNumber(statementId)
		setIsOpenConvertToTrip((prev) => !prev);
	};
	// toggle add serial code
	const toggleCreateSerialModal = (statementId) => {
		setIsCreateSerialModalOpen((prev) => !prev);
	};


	const UpdateStatement = async () => {
		const data = {
			file_number: selectedOperation.file_number,
			operation_number: selectedOperation.operation_number,
			credential_number: selectedOperation.credential_number,
			supervisor_name: selectedOperation.supervisor_name,
			supervisor_number: `${selectedOperation.phone_number}`,
			supervisor_phone_code: selectedOperation.phone_code?.value,
			supervisor_phone_country_id: selectedOperation.phone_code?.id,
		};
		const res = await updateOperationStatement(
			selectedOperation.reference_number,
			data
		);
		if (res?.data || res.status === 200) {
			store.addNotification({
				title: "info!",
				message: "Update operation Statement Numbers successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					toggle();
					setReload((prev) => !prev);
				},
			});
		}
	};

	useEffect(() => {
		setActiveStatus(filters?.status);
	}, [filters]);

	//upload traveller excel file
	const UploadFile = async () => {
		let data = {
			file: fileTraveller?.uuid,
		};

		const res = await UploadTravellerFile(selectedTraveller?.id, data);
		if (res?.status === 200 || res?.status === 201) {
			store.addNotification({
				title: "info!",
				message: res?.data?.message ?? "Upload Traveller File successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					toggleTraveller();
					setFileTraveller();
					setReload((prev) => !prev);
				},
			});
		}
	};

	useEffect(() => {
		const GetUmrahRequest = async () => {
			const res = await FetchUmerahRequest(filterSend);

			setUmrahRequest(res?.data?.data)
			setMetaData(res?.data?.meta)
		};
		GetUmrahRequest();
	}, [filterSend, reload]);

	function goTo(page) {
		setFilterSend({ page: page });
	}

	return (
		<div className="operation-statement-list">
			<div className="d-flex flex-wrap justify-content-between align-items-center w-100 border-bottom pb-4">
				<ul className="status-filter">
					{statusList?.map((item) => {
						return (
							<li
								className={`${activeStatus === item.id ? "active" : ""}`}
								onClick={() => {
									setActiveStatus(item.id);
									setFilters({ ...filters, status: item?.id, page: null });
									// fetchStatementList(item?.id);
								}}
								key={item?.id}
							>
								{item.title}
								<span
									className={`${item.id === "new" ? "new" : ""} badge mx-1`}
								>
									{item?.count}
									{item.id === "new" ? ` ${operationStatement.new}` : ""}
								</span>
							</li>
						);
					})}
				</ul>

				<div className="d-flex flex-wrap gap-10 w-100 os-actions-btns">
					{isUmrahCompany &&
						<ShowForPermission permission="Manage-operation-statement">
							<button className="btn outline-btn justify-content-center flex-grow-1" onClick={toggleCompany}>
								<SendIcon color="#D2AB67" />
								{operationStatement.SendToCompany}
							</button>
						</ShowForPermission>
					}
					<ShowForPermission permission="Manage-operation-statement">
						<button className="btn filled-btn justify-content-center flex-grow-1"
							onClick={() => {
								userShouldAddSerial ? toggleCreateSerialModal() : history.push(`/${bundle}/operation-view`);
							}}
						>
							<PlusIcon color="#fff" />
							{operationStatement.AddNew}
						</button>
					</ShowForPermission>
				</div>
			</div>

			<div className='table-wrapper'>
				<table className="statement-table">
					{statementsList?.map((statementItem, index) => {
						return (
							<StatementTableRow
								key={statementItem?.reference_number}
								statementItem={statementItem}
								toggle={toggle}
								toggleTraveller={toggleTraveller}
								toggleUmrah={toggleUmrah}
								isUmrahCompany={isUmrahCompany}
								toggleConvertToTripModal={toggleConvertToTripModal}
							/>
						);
					})}
				</table>
			</div>
			{/* sent tabel */}
			<div className='table'>

				{activeStatus === "sent" && UmrahRequest?.length > 0 && UmrahRequest?.map((statementItem, index) => {
					return (
						<StatementTableRow
							key={statementItem?.reference_number}
							statementItem={statementItem}
							toggle={toggle}
							toggleTraveller={toggleTraveller}
							toggleUmrah={toggleUmrah}
							isAction={false}
							isUmrahCompany={isUmrahCompany}
							toggleConvertToTripModal={toggleConvertToTripModal}
						/>
					);
				})}

				<div className="mt-4 pb-3">
					{activeStatus === "sent" && UmrahRequest?.length > 0 &&
						<Pagination info={metaData} goTo={goTo} />}
				</div>
			</div>

			<UpdateOperationModal
				isOpen={isOpenModal}
				toggle={toggle}
				data={selectedOperation}
				setData={setSelectedOperation}
				update={UpdateStatement}
			/>
			{isOpenSendCompanyModal &&
				<SendToCompanyModal
					isOpen={isOpenSendCompanyModal}
					toggle={toggleCompany}
					setReload={setReload}
					agentsList={agentsList}
				/>
			}
			<UploadTravellerFileModal
				isOpen={isOpenTraveller}
				toggle={toggleTraveller}
				data={selectedTraveller}
				fileTraveller={fileTraveller}
				setFileTraveller={setFileTraveller}
				uploadFn={UploadFile}
			/>
			{isOpenUmrahCompany &&
				<SendToUmrahCompanyModal
					isOpen={isOpenUmrahCompany}
					toggle={toggleUmrah}
					statementRefNumber={currentRefNumber}
					setReload={setReload}
				/>
			}
			{isOpenConvertToTrip &&
				<ConvertStatementToTripModal
					isOpen={isOpenConvertToTrip}
					toggle={toggleConvertToTripModal}
					statementId={currentRefNumber}
					setReload={setReload}
				/>
			}
			{isCreateSerialModalOpen &&
				<OperatingNewSerialModal
					isOpen={isCreateSerialModalOpen}
					toggle={toggleCreateSerialModal}
				/>
			}
		</div>
	);
}

export function StatementTableRow({
	statementItem,
	toggle,
	toggleTraveller,
	toggleUmrah,
	toggleConvertToTripModal,
	isAction = true,
	isUmrahCompany
}) {
	const history = useHistory();
	const { operationStatement } = Locale;

	const [showActions, setShowActions] = useState(false);

	const filesUrl = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

	function closeActionMenu() {
		setShowActions(false);
	}

	const detectClickOutsideRef = useDetectClickOutside({
		onTriggered: closeActionMenu,
	});

	function viewStatementItems(item) {
		switch (item) {
			case "flight":
				return <DepartureIcon color="#707170" />;
			case "hotels":
				return <HotelIcon color="#707170" />;
			case "transportation":
				return <TransportationIcon color="#707170" />;
			case "attractions":
				return <OperatingAttractionIcon />;
			case "visas":
				return <VisaIcon color="#707170" />;
			case "other_services":
				return <OperatingOtherIcon />;

			default:
				break;
		}
	}
	function translateService(item) {
		switch (item) {
			case "flight":
				return operationStatement.flight;
			case "hotels":
				return operationStatement.hotels;
			case "transportation":
				return operationStatement.transportation;
			case "attractions":
				return operationStatement.attractions;
			case "visas":
				return operationStatement.visas;
			case "other_services":
				return operationStatement.otherservices;

			default:
				return item;
		}
	}
	const itemsCount = 3;
	const showStatementItems =
		statementItem?.items?.length > itemsCount
			? statementItem?.items?.slice(0, itemsCount)
			: statementItem?.items;
	const hiddenStatementItems =
		statementItem?.items?.length > itemsCount
			? statementItem?.items?.slice(itemsCount)
			: [];

	const closestDepartureDate =
		statementItem?.trip?.departures?.length > 0
			? moment.min(
				statementItem?.trip?.departures?.map((departure) =>
					departure?.date ? moment(departure?.date) : null
				)
			)
			: null;
	const { printOperationStatementInvoice } = usePrintingInvoices();

	return (
		<tr key={statementItem?.reference_number}>
			<td>
				<div className="d-flex align-items-center">
					<span
						className={`status ${statementItem?.status}`}
						style={{
							background:
								statementItem?.company_reference_number &&
									statementItem?.company_reference_number.includes("Temp")
									? "#FFA000"
									: null,
						}}
					/>
					{/* statemetn info */}
					<div className="statement-info">
						<span
							className="confirmation-no"
							style={{
								color:
									statementItem?.company_reference_number &&
										statementItem?.company_reference_number.includes("Temp")
										? "#FFA000"
										: null,
							}}
						>
							{statementItem?.company_reference_number
								? `# ${statementItem?.company_reference_number}`
								: null}
						</span>
						<span className="file-no">
							{operationStatement.FileNo}:{statementItem?.file_number}
						</span>
						{statementItem?.items?.length > 0 ? (
							<div className="elements">
								{showStatementItems?.map((item, index) => {
									return (
										<span
											className="ele-icon"
											style={{
												left: `${(index + 1) * -4}px`,
												zIndex: `${itemsCount - index + 1}`,
											}}
											key={index}
										>
											{viewStatementItems(item)}
										</span>
									);
								})}
								{statementItem?.items?.length > itemsCount && (
									<span
										className="ele-icon count"
										style={{ left: `${(itemsCount + 1) * -4}px` }}
									>
										+{statementItem?.items?.length - itemsCount}
									</span>
								)}

								{/* hidden elemets */}
								{hiddenStatementItems.length > 0 ? (
									<div className="hidden-elements">
										{hiddenStatementItems?.map((item, index) => {
											return (
												<p
													className="d-flex align-items-center my-1"
													key={`${item}- ${index}`}
												>
													<span className="ele-icon">
														{viewStatementItems(item)}
													</span>
													<span className="mx-2">{translateService(item)}</span>
												</p>
											);
										})}
									</div>
								) : null}
							</div>
						) : null}
					</div>
				</div>
			</td>
			{/* agent data */}
			<td>
				<div className="agent-info">
				<span className="">{operationStatement.AgentName}</span>
				<span className="name">{statementItem?.agent?.name}</span>
				<p className="d-flex align-items-center">
					<img
						src={`${filesUrl}/${statementItem?.agent?.country?.flag}`}
						alt="Saudi Arabia"
						className="rounded-circle"
						width={"16px"}
						height={"16px"}
					/>
					<span className="country mx-1 mt-1">
						{statementItem?.agent?.country?.name}
					</span>
				</p>
				</div>
			</td>
			<td>
				<CategoryCard
					categroyTitle={operationStatement.Arrival}
					categroyInfo={{
						name: ` ${statementItem?.trip?.arrival_port?.code
							? statementItem?.trip?.arrival_port?.code
							: "-"
							}  
					${statementItem?.trip?.arrival_trip_number
								? ` - ${statementItem?.trip?.arrival_trip_number}`
								: ""
							}`,
						icon: <FlightIcon color="#00CFE8" />,
						color: "#00CFE8",
					}}
					categorySub={[
						{
							name: statementItem?.trip?.arrival_date
								? moment(statementItem?.trip?.arrival_date).format(
									"DD-MM-YYYY hh:mm"
								)
								: null,
							icon: <DepartureIcon color="#219653" />,
						},
						{
							name: closestDepartureDate
								? moment(closestDepartureDate).format("DD-MM-YYYY hh:mm")
								: null,
							icon: <ArrivalIcon />,
						},
					]}
				/>
			</td>
			<td>
				<CategoryCard
					categroyTitle={operationStatement.MakkahHotel}
					categroyInfo={{
						name: statementItem?.makkah_hotel?.hotel?.name,
						icon: <HotelIcon color="#FF9F43" />,
						color: "#FF9F43",
					}}
					categorySub={[
						{
							name: statementItem?.makkah_hotel
								? moment(statementItem?.makkah_hotel?.check_in_date).format(
									"DD-MM-YYYY hh:mm"
								)
								: null,
							icon: <CircleArrowRight color="#219653" />,
						},
						{
							name: statementItem?.makkah_hotel
								? moment(statementItem?.makkah_hotel?.check_out_date).format(
									"DD-MM-YYYY hh:mm"
								)
								: null,
							icon: <CircleArrowLeft color="#EA5455" />,
						},
					]}
				/>
			</td>
			<td>
				<CategoryCard
					categroyTitle={operationStatement.MadinahHotel}
					categroyInfo={{
						name: statementItem?.madinah_hotel?.hotel?.name,
						icon: <HotelIcon color="#FF9F43" />,
						color: "#FF9F43",
					}}
					categorySub={[
						{
							name: statementItem?.madinah_hotel
								? moment(statementItem?.madinah_hotel?.check_in_date).format(
									"DD-MM-YYYY hh:mm"
								)
								: null,
							icon: <CircleArrowRight color="#219653" />,
						},
						{
							name: statementItem?.madinah_hotel
								? moment(statementItem?.madinah_hotel?.check_out_date).format(
									"DD-MM-YYYY hh:mm"
								)
								: null,
							icon: <CircleArrowLeft color="#EA5455" />,
						},
					]}
				/>
			</td>
			<td>
				{/*  */}
				<CategoryCard
					categroyTitle={operationStatement.Transportation}
					categroyInfo={{
						name: statementItem?.transportation?.company_name,
						icon: <TransportationIcon color="#EA5455" />,
						color: "#EA5455",
					}}
					categorySub={[
						{
							name: `${operationStatement.CredentialNo}: ${statementItem?.transportation?.credential_number ?? "-"
								}`,
							icon: <CheckCircleIcon />,
						},
						{
							name: `${operationStatement.OperatingID}:  ${statementItem?.transportation?.operation_id ?? "-"
								}`,
							icon: <SettingIcon color="#219653" width="16px" height="16px" />,
						},
					]}
				/>
			</td>
			{/* actions */}
			<td>
				{isAction ?
					<div className="d-flex flex-column align-self-start">
						<p className="action-label">{operationStatement.Actions}</p>
						<ShowForPermission permission="Manage-operation-statement">
							<div className="actions-btn d-flex align-items-center">
								{statementItem?.status === "draft" ? (
									<button
										className="btn edit-draft-btn"
										onClick={() =>
											history.push(
												`operation-view/${statementItem?.reference_number}`
											)
										}
									>
										<EditIcon />
										<span>{operationStatement.EditDraft}</span>
									</button>
								) : (
									<>
										<button
											className="btn p-0"
											onClick={() =>
												history.push(
													`operation-view/${statementItem?.reference_number}`
												)
											}
										>
											<EyelIcon />
										</button>
										<button
											className="btn p-0 mx-2"
											onClick={() =>
												history.push(
													`operation-view/${statementItem?.reference_number}`
												)
											}
										>
											<EditIcon />
										</button>
										<button className="btn p-0 ">
											<DownloadIcon />
										</button>
									</>
								)}

								<div>
									<StatemenRowtActions
										showActions={showActions}
										toggleActionMenu={() => setShowActions((prev) => !prev)}
										toggle={toggle}
										data={statementItem}
										closeActionMenu={closeActionMenu}
										toggleTraveller={toggleTraveller}
										toggleUmrah={toggleUmrah}
										toggleConvertToTripModal={toggleConvertToTripModal}
										isUmrahCompany={isUmrahCompany}
									/>
								</div>
							</div>
						</ShowForPermission>
					</div> :
					<div className="d-flex gap-10">
						<button className="btn"
							onClick={() => printOperationStatementInvoice(statementItem?.id)}
						>
							<ExportToPdf color="#9C9FAB" />
						</button>
						<button
							className="btn p-0"
							onClick={() =>
								history.push(
									`operation-view/${statementItem?.reference_number}/sent`
								)
							}
						>
							<EyelIcon />
						</button>
					</div>

				}
			</td>
		</tr>
	);
}

export function StatemenRowtActions({
	showActions,
	toggle,
	data,
	closeActionMenu,
	toggleTraveller,
	toggleUmrah,
	toggleConvertToTripModal,
	isUmrahCompany,
	toggleActionMenu
}) {
	const { operationStatement } = Locale;
	const { printOperationStatementInvoice } = usePrintingInvoices();
	const fetchFile = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

	const exportExcelSingleItem = async () => {
		const res = await exportExcelSingleOperation(data?.reference_number);
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				// console.log
				const blob = new Blob([res.data], {
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`operation_statement_${data?.reference_number}`
				);
				anchor.click();
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	// return (
	// 	<ul className={`actions-list ${showActions ? "show" : ""}`}>
	// 		<li
	// 			className="item stroke"
	// 			role="button"
	// 			onClick={() => {
	// 				toggle(data);
	// 				closeActionMenu();
	// 			}}
	// 		>
	// 			<EditIcon />
	// 			<span className="mx-1"> {operationStatement.UpdateNumbersID}</span>
	// 		</li>
	// 		<hr />
	// 		<h5>{operationStatement.OperatingCommand}</h5>
	// 		<li
	// 			className="item"
	// 			role="button"
	// 			onClick={() => printOperationStatementInvoice(data?.id)}
	// 		>
	// 			<ExportToPdf color="#292D32" />
	// 			<span className="mx-1">{operationStatement.ExportPDF}</span>
	// 		</li>
	// 		<li
	// 			className="item"
	// 			role="button"
	// 			onClick={() => exportExcelSingleItem()}
	// 		>
	// 			<ExportToPdf color="#292D32" />
	// 			<span className="mx-1">{operationStatement.exportExcel}</span>
	// 		</li>
	// 		{/* Send to umrah company */}
	// 		<ShowForPermission permission="Manage-operation-statement">
	// 			{!isUmrahCompany && data?.has_passports && !data.is_sent_to_company ?
	// 				<li className="item" role="button"
	// 					onClick={() => {
	// 						toggleUmrah(data?.reference_number);
	// 						closeActionMenu();
	// 					}}
	// 				>
	// 					<SendIcon color="#292D32" />
	// 					<span className="mx-1">{operationStatement.sendToUmrahCompany}</span>
	// 				</li>
	// 				: null
	// 			}
	// 		</ShowForPermission>
	// 		{/* convert to trip */}
	// 		<ShowForPermission permission="Manage-operation-statement">
	// 			{data?.has_passports && !data?.has_product &&
	// 				(data?.makkah_hotel || data?.madinah_hotel || data?.items.includes('hotels')) ?
	// 				<li className="item" role="button"
	// 					onClick={() => {
	// 						toggleConvertToTripModal(data?.id);
	// 						closeActionMenu();
	// 					}}
	// 				>
	// 					<SendIcon color="#292D32" />
	// 					<span className="mx-1">{operationStatement.convertToTrip}</span>
	// 				</li>
	// 				: null
	// 			}
	// 		</ShowForPermission>
	// 		{/* Upload Traveller file */}
	// 		<li
	// 			className="item"
	// 			role="button"
	// 			onClick={() => {
	// 				toggleTraveller(data);
	// 				closeActionMenu();
	// 			}}
	// 		>
	// 			<ExportToPdf color="#292D32" />
	// 			<span className="mx-1">{operationStatement.uploadTraveler}</span>
	// 		</li>
	// 		{/* Download Traveller file */}
	// 		{data?.travellers_file && (
	// 			<li
	// 				className="item"
	// 				role="button"
	// 				onClick={() => {
	// 					const anchor = document.createElement("a");
	// 					anchor.target = "_blank";
	// 					anchor.href = `${fetchFile}/${data?.travellers_file}`;
	// 					anchor.setAttribute("download", `${"Download File"}`);
	// 					anchor.click();
	// 					closeActionMenu();
	// 				}}
	// 			>
	// 				<ExportToPdf color="#292D32" />
	// 				<span className="mx-1">Download Traveler</span>
	// 			</li>
	// 		)}

	// 		{/* <li className="item">
	// 			<WhatsAppIcon color="#292D32" />
	// 			<span className="mx-1">{operationStatement.SendWhatsApp}</span>
	// 		</li>
	// 		<li className="item">
	// 			<SendIcon color="#292D32" />
	// 			<span className="mx-1">{operationStatement.SendEmail}</span>
	// 		</li> */}
	// 		{/* <hr /> */}
	// 		{/* <h5>{operationStatement.TransportCommand}</h5>
	// 		<li className='item'>
	// 			<ExportToPdf color='#292D32' />
	// 			<span className='mx-1'>{operationStatement.ExportPDF}</span>
	// 		</li>
	// 		<li className='item'>
	// 			<WhatsAppIcon color='#292D32' />
	// 			<span className='mx-1'>{operationStatement.SendWhatsApp}</span>
	// 		</li>
	// 		<li className='item'>
	// 			<SendIcon color='#292D32' />
	// 			<span className='mx-1'>{operationStatement.SendEmail}</span>
	// 		</li>
	// 		<hr />
	// 		<li className='item'>
	// 			<FlagIcon />
	// 			<span className='mx-1'>{operationStatement.Arrivalnotice}</span>
	// 		</li> */}
	// 		{/* <li className="item pointer">
	// 			<CancelIcon />
	// 			<span className="mx-1">
	// 				{operationStatement.CancelOperatingStatement}
	// 			</span>
	// 		</li> */}
	// 	</ul>
	// );

	return (
		<Dropdown isOpen={showActions} toggle={toggleActionMenu} >
			<DropdownToggle className="btn p-0 mx-2 bg-transparent border-0">
				<MoreIcon />
			</DropdownToggle>
			<DropdownMenu positionFixed>
				<DropdownItem onClick={() => { toggle(data) }}>
					<EditIcon />
					<span className="mx-1"> {operationStatement.UpdateNumbersID}</span>
				</DropdownItem>
				<DropdownItem divider />
				<DropdownItem header>{operationStatement.OperatingCommand}</DropdownItem>
				<DropdownItem onClick={() => printOperationStatementInvoice(data?.id)}>
					<ExportToPdf color="#292D32" />
					<span className="mx-1">{operationStatement.ExportPDF}</span>
				</DropdownItem>
				<DropdownItem onClick={() => exportExcelSingleItem()}>
					<ExportToPdf color="#292D32" />
					<span className="mx-1">{operationStatement.exportExcel}</span>
				</DropdownItem>
				<ShowForPermission permission="Manage-operation-statement">
					{!isUmrahCompany && data?.has_passports && !data.is_sent_to_company ?
						<DropdownItem onClick={() => toggleUmrah(data?.reference_number)}>
							<SendIcon color="#292D32" />
							<span className="mx-1">{operationStatement.sendToUmrahCompany}</span>
						</DropdownItem>
						: null
					}
				</ShowForPermission>
				<ShowForPermission permission="Manage-operation-statement">
					{data?.has_passports && !data?.has_product &&
						(data?.makkah_hotel || data?.madinah_hotel || data?.items.includes('hotels')) ?
						<DropdownItem onClick={() => toggleConvertToTripModal(data?.id)}>
							<SendIcon color="#292D32" />
							<span className="mx-1">{operationStatement.convertToTrip}</span>
						</DropdownItem>
						: null
					}
				</ShowForPermission>
				<DropdownItem onClick={() => toggleTraveller(data)}>
					<ExportToPdf color="#292D32" />
					<span className="mx-1">{operationStatement.uploadTraveler}</span>
				</DropdownItem>
				{data?.travellers_file && (
					<DropdownItem onClick={() => {
						const anchor = document.createElement("a");
						anchor.target = "_blank";
						anchor.href = `${fetchFile}/${data?.travellers_file}`;
						anchor.setAttribute("download", `${"Download File"}`);
						anchor.click();
					}}>
						<ExportToPdf color="#292D32" />
						<span className="mx-1">Download Traveler</span>
					</DropdownItem>
				)}
			</DropdownMenu>
		</Dropdown>
	)
}
